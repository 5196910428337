import React from "react";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { colors } from "../../util";

export default function ConfirmationDialog({  show, close, message, confirmAction }) {

  return (
    <div>
      <Dialog
        open={show}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h2" sx={{ color: colors.black }}>
            {message}
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={close}
            style={{
              background: colors.grey,
              color: colors.white,
              padding: "5px 20px",
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              confirmAction();
              close()
            }}
            style={{
              background: colors.primaryColor,
              color: colors.white,
              padding: "5px 20px",
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

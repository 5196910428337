import { useState, useContext, useEffect } from "react";
import { Box } from "@mui/system";
import {
  Avatar,
  Button,
  CardHeader,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Collaborationsicon from "../../components/Public-icons/collaborations";
import Excel from "../../components/Public-icons/excel";
import Filter from "../../components/Public-icons/filter";
import TransactionIcon from "../../components/Public-icons/transactionicon";
import ServiceFeemodal from "./ServiceFeemodal";
import { TransactionContext } from "../../context/TransactionProvider";
import { constantStyle } from "../../util/constant";
import { colors } from "../../util";
import { transactionStyle } from "../../util/transactionStyle";
import { servicefeecolumns } from "./Data";
import "./style.css";
import { Loader } from "../../components/Loader";

export default function ServiceFee() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowData, setRowData] = useState();
  const {
    collabrations,
    exportFile,
    formatDateTime,
    getCollaborations,
    loader,
  } = useContext(TransactionContext);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [open, setOpen] = useState(false);
  const handleclose = () => {
    setOpen(false);
  };
  const handlopen = (data) => {
    setRowData(data);
    setOpen(true);
  };

  const getRowCell = (column, value, row, index) => {
    switch (column.id) {
      case "creatorName":
        return (
          <TableCell key={index} align={column.align}>
            <CardHeader
              sx={{ padding: 0, marginRight: "0" }}
              title={row.creatorName}
              avatar={
                <Avatar
                  sx={{
                    height: 17,

                    width: 17,

                    marginRight: "0",
                  }}
                  alt={row.creatorName}
                  src={row.creatorPhoto}
                />
              }
            />
          </TableCell>
        );

      case "collaboratorName":
        return (
          <TableCell key={index} align={column.align}>
            <CardHeader
              sx={{ padding: 0, marginRight: "0" }}
              title={row.collaboratorName}
              avatar={
                <Avatar
                  sx={{
                    height: 17,

                    width: 17,

                    marginRight: "0",
                  }}
                  alt={row.collaboratorName}
                  src={row.collaboratorPhoto}
                />
              }
            />
          </TableCell>
        );

      case "createdAt":
        return (
          <TableCell key={index} align={column.align}>
            {formatDateTime(value.toDate() ? value.toDate() : null)}
          </TableCell>
        );

      case "amount":
        return (
          <TableCell key={index} align={column.align}>
            {row.amount === "Barter" ? "Barter" : "₹ " + row.amount}
          </TableCell>
        );

      case "receiverAmount":
        return (
          <TableCell key={index} align={column.align}>
            {row.receiverAmount === "Barter"
              ? "Barter"
              : "₹ " + (row.amount - row.receiverAmount).toFixed(2)}
          </TableCell>
        );

      case "icon":
        return (
          <TableCell key={index} align={column.align}>
            <RemoveRedEyeIcon
              sx={{ color: colors.primaryColor, cursor: "pointer" }}
              onClick={() => handlopen(row)}
            />
          </TableCell>
        );

      default:
        return (
          <TableCell key={column.id} align={column.align}>
            {column.format && typeof value === "number"
              ? column.format(value)
              : value}
          </TableCell>
        );
    }
  };

  // export data to excel
  const handleExport = () => {
    const dataToExport = collabrations.map((collabrations, index) => ({
      srNo: index + 1,
      CreatorName: collabrations.creatorName,
      CollaboratorName: collabrations.collaboratorName,
      Amount:
        collabrations.amount === "Barter" ? "Barter" : collabrations.amount,
      ServiceFee:
        collabrations.receiverAmount === "Barter"
          ? "Barter"
          : collabrations.amount - collabrations.receiverAmount,
      Date: new Date(
        collabrations.createdAt.seconds * 1000 +
          collabrations.createdAt.nanoseconds / 1000000
      ).toLocaleDateString(),
    }));

    exportFile(dataToExport, "Collabrations", "collabrations.xlsx");
  };
  const { totalPostAmount, totalServiceFee } = collabrations.reduce(
    (totals, { amount, receiverAmount }) => ({
      totalPostAmount:
        totals.totalPostAmount + (amount === "Barter" ? 0 : amount),
      totalServiceFee:
        totals.totalServiceFee +
        (receiverAmount === "Barter" ? 0 : amount - receiverAmount),
    }),
    { totalPostAmount: 0, totalServiceFee: 0 }
  );

  useEffect(() => {
    getCollaborations(page, rowsPerPage);
  }, [page, rowsPerPage, getCollaborations]);
  return (
    <Box>
      <Box className="rightbtn">
        <Button className="filterbtn" sx={constantStyle.filterBtn}>
          <Filter />
        </Button>
        <Button
          onClick={handleExport}
          variant="contained"
          sx={constantStyle.letterSpacing}
        >
          <Excel /> Export
        </Button>
      </Box>
      <Box sx={{ display: "flex", gap: "30px", mb: "30px" }}>
        <Paper
          elevation={0}
          sx={{ background: colors.white }}
          className="totalamountbox"
        >
          <Typography variant="body2">
            Total Number of Collaborations
          </Typography>
          <Box sx={transactionStyle.servicefeeBox}>
            <Typography variant="h1" sx={transactionStyle.textSize}>
              {collabrations.length}
            </Typography>
            <Collaborationsicon />
          </Box>
        </Paper>
        <Paper
          elevation={0}
          sx={{ background: colors.white }}
          className="totalamountbox"
        >
          <Typography variant="body2">Total Amount of Transactions</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: "10px",
            }}
          >
            <Typography sx={transactionStyle.textSize} variant="h1">
              ₹ {totalPostAmount}
            </Typography>
            <TransactionIcon />
          </Box>
        </Paper>
        <Paper
          elevation={0}
          sx={{ background: colors.white }}
          className="totalamountbox"
        >
          <Typography variant="body2">Total Amount of Service Fee</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: "10px",
            }}
          >
            <Typography sx={transactionStyle.textSize} variant="h1">
              ₹ {totalServiceFee.toFixed(2)}
            </Typography>
            <TransactionIcon />
          </Box>
        </Paper>
      </Box>
      <Paper sx={transactionStyle.paper}>
        {loader ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "50vh",
              backdropFilter: "blur(5px)",
            }}
          >
            <CircularProgress size={48} style={{ color: colors.light_red }} />
          </Box>
        ) : (
          <TableContainer sx={{ overflowY: "auto" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {servicefeecolumns.map((column, index) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {collabrations.length > 0 ? (
                <TableBody>
                  {collabrations
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          {servicefeecolumns.map((column, index) => {
                            const value = row[column.id];
                            const cellData = getRowCell(
                              column,
                              value,
                              row,
                              index
                            );
                            return cellData;
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={servicefeecolumns.length}>
                      No data found
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
      </Paper>
      {collabrations.length > 10 && (
        <TablePagination
          rowsPerPageOptions={[]}
          labelRowsPerPage=""
          component="article"
          count={collabrations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            native: false,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <ServiceFeemodal open={open} actions={handleclose} data={rowData} />
    </Box>
  );
}

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import ConfirmationDialog from "../../components/Confirmation-Message";
import Edit from "../../components/Public-icons/edit";
import { BrandPostingContext } from "../../context/BrandPostProvider";
import { brandStyle } from "../../util/brandStyle";
import CreateBrandPostingModal from "./CreateBrandPosting";

export default function SaveDraft() {
  const [selectedCardDetail, setSelectedCardDetail] = useState("");
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState();
  const handleClose = () => {
    setOpen(false);
  };
  const handlecardClick = (brandPostingData) => {
    setOpen(true);
    setSelectedCardDetail(brandPostingData);
  };

  // this commented  code is for delete function
  // eslint-disable-next-line
  // const handleDelete = (brandPostingData) => {
  //   setSelectedCardDetail(brandPostingData);
  //   setShow(true);
  // };
  const { brandDraftData, deleteBrandData } = useContext(BrandPostingContext);

  return (
    <Box className="brandposting-crds">
      {brandDraftData.map((brandPostingData, index) => {
        return (
          <Card className="postcards" key={index}>
            <Box>
              <Link
                to={`/brand-posting-details`}
                state={{ cardDetail: brandPostingData }}
              >
                <Box sx={brandStyle.brandCardData}>
                  <CardContent>
                    <Typography variant="body1">Name</Typography>
                    <Typography variant="subtitle2">
                      {brandPostingData.brandName}
                    </Typography>
                  </CardContent>

                  <CardContent>
                    <Typography variant="body1">Genre</Typography>
                    <Box sx={brandStyle.genreCategory}>
                      {brandPostingData.brandCategory.map((category, index) => {
                        return (
                          <Typography variant="subtitle2" key={index}>
                            {category.text +
                              (index !==
                              brandPostingData.brandCategory.length - 1
                                ? ", "
                                : "")}
                          </Typography>
                        );
                      })}
                    </Box>
                  </CardContent>
                  <CardContent>
                    <Typography variant="body1">Requirements</Typography>
                    <Typography variant="subtitle2">
                      {brandPostingData.requiredFollower.text}
                    </Typography>
                  </CardContent>

                  <CardContent>
                    <Typography variant="body1">Platform</Typography>
                    <Typography variant="subtitle2">
                      {brandPostingData.platform.length === 0
                        ? ""
                        : brandPostingData.platform[0].text}
                    </Typography>
                  </CardContent>

                  <CardContent>
                    <Typography variant="body1">Deliverables</Typography>
                    <Box sx={brandStyle.categoryBox}>
                      {brandPostingData.deliverables.map((delivery, index) => {
                        return (
                          <Typography variant="subtitle2" key={index}>
                            {delivery.text +
                              (index !==
                              brandPostingData.deliverables.length - 1
                                ? ", "
                                : "")}
                          </Typography>
                        );
                      })}
                    </Box>
                  </CardContent>
                  <CardContent>
                    <Typography variant="body1">What Do You Get</Typography>
                    <Typography variant="subtitle2">
                      {brandPostingData.what_do_you_get}
                    </Typography>
                  </CardContent>
                </Box>

                {brandPostingData.brandLogo && (
                  <CardMedia
                    sx={brandStyle.categorypic}
                    component="img"
                    image={brandPostingData.brandLogo}
                    alt="Brand Logo"
                  />
                )}
              </Link>
            </Box>
            <IconButton
              disableRipple
              className="editdeletbtn"
              onClick={() => handlecardClick(brandPostingData)}
            >
              <Edit />
            </IconButton>

            {/* 
               comment code is for Delete Component 
              <Link to="#" onClick={() => handleDelete(brandPostingData)}>
                  <Delete />
                </Link> */}
          </Card>
        );
      })}

      {open && (
        <CreateBrandPostingModal
          open={open}
          actions={handleClose}
          isDraft={true}
          editData={selectedCardDetail}
        />
      )}
      <ConfirmationDialog
        show={show}
        data={selectedCardDetail}
        close={() => setShow(false)}
        confirmAction={() => deleteBrandData(selectedCardDetail)}
        message="Are you sure you want to delete this post?"
      />
    </Box>
  );
}

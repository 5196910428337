import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { colors } from "../../util";
import AppGraph from "./AppGraph";
import "./style.css";
import { dashboardStyle } from "../../util/dashboardStyle";

export default function Dashboard() {
  const [appEventData, setAppEventData] = useState([]);
  const [appUsageData, setAppUsageData] = useState([]);
  const [audiencData, setAudienceData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const [appEventDataRes, appUsageDataRes, audiencDataRes] =
        await Promise.all([
          fetch(
            "https://api-metrics.flurry.com/public/v1/data/appEvent/year/app;show=name/country;show=name/gender;show=name?metrics=activeDevices,timeSpent,newDevices&dateTime=2023-01-01/2024-01-01&token=eyJhbGciOiJIUzI1NiIsImtpZCI6ImZsdXJyeS56dXVsLnByb2Qua2V5c3RvcmUua2V5LjIifQ.eyJpc3MiOiJodHRwczovL3p1dWwuZmx1cnJ5LmNvbTo0NDMvdG9rZW4iLCJpYXQiOjE2Nzk0ODU1NzAsImV4cCI6MzMyMzYzOTQzNzAsInN1YiI6IjQ5NDIzNSIsImF1ZCI6IjQiLCJ0eXBlIjo0LCJqdGkiOiIxMzQyMCJ9.wjv3TsK5fpFvSd9Ida5Gkv7JRwDBcCNj07PFDFAsI8I"
          ),
          fetch(
            "https://api-metrics.flurry.com/public/v1/data/appUsage/year/app;show=name/country;show=name/gender;show=name?metrics=activeDevices,timeSpent,newDevices&dateTime=2023-01-01/2024-01-01&token=eyJhbGciOiJIUzI1NiIsImtpZCI6ImZsdXJyeS56dXVsLnByb2Qua2V5c3RvcmUua2V5LjIifQ.eyJpc3MiOiJodHRwczovL3p1dWwuZmx1cnJ5LmNvbTo0NDMvdG9rZW4iLCJpYXQiOjE2Nzk0ODU1NzAsImV4cCI6MzMyMzYzOTQzNzAsInN1YiI6IjQ5NDIzNSIsImF1ZCI6IjQiLCJ0eXBlIjo0LCJqdGkiOiIxMzQyMCJ9.wjv3TsK5fpFvSd9Ida5Gkv7JRwDBcCNj07PFDFAsI8I"
          ),
          fetch(
            "https://api-metrics.flurry.com/public/v1/data/audience/year/app;show=name/country;show=name/gender;show=name?metrics=activeDevices,timeSpent,newDevices&dateTime=2023-01-01/2024-01-01&token=eyJhbGciOiJIUzI1NiIsImtpZCI6ImZsdXJyeS56dXVsLnByb2Qua2V5c3RvcmUua2V5LjIifQ.eyJpc3MiOiJodHRwczovL3p1dWwuZmx1cnJ5LmNvbTo0NDMvdG9rZW4iLCJpYXQiOjE2Nzk0ODU1NzAsImV4cCI6MzMyMzYzOTQzNzAsInN1YiI6IjQ5NDIzNSIsImF1ZCI6IjQiLCJ0eXBlIjo0LCJqdGkiOiIxMzQyMCJ9.wjv3TsK5fpFvSd9Ida5Gkv7JRwDBcCNj07PFDFAsI8I"
          ),
        ]);
      const parsedAppEventData = await appEventDataRes.json();
      const parsedAppUsageData = await appUsageDataRes.json();
      const parsedAudiencData = await audiencDataRes.json();

      setAppEventData(parsedAppEventData?.rows);
      setAppUsageData(parsedAppUsageData?.rows);
      setAudienceData(parsedAudiencData?.rows);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            backdropFilter: "blur(5px)",
          }}
        >
          <CircularProgress size={48} style={{ color: colors.light_red }} />
        </Box>
      ) : (
        <Box>
          <Box className="graph-box">
            <Box>
              <Typography variant="body2" sx={dashboardStyle.textStyle}>
                appEventData
              </Typography>
              {appEventData.length !== 0 ? (
                <AppGraph width={600} height={300} data={appEventData} />
              ) : (
                <Box>No Data</Box>
              )}
            </Box>
            <Box>
              <Typography variant="body2" sx={dashboardStyle.textStyle}>
                appEventData
              </Typography>
              {appEventData.length !== 0 ? (
                <AppGraph width={600} height={300} data={appUsageData} />
              ) : (
                <Box>No Data</Box>
              )}
            </Box>

            <Box>
              <Typography variant="body2" sx={dashboardStyle.textStyle}>
                audienceData
              </Typography>
              {audiencData.length !== 0 ? (
                <AppGraph width={600} height={300} data={audiencData} />
              ) : (
                <Box>No Data</Box>
              )}
            </Box>
          </Box>

          <Box sx={{ marginBottom: 2 }}>
            <Typography variant="body2">
              For More Information click Here
              <Link
                to="https://dev.flurry.com/metrics/1"
                style={dashboardStyle.linkStyle}
                target="_blank"
                rel="noreferrer"
              >
                More Info
              </Link>
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

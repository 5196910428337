import { Box, Card } from '@mui/material';
import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';
import { colors } from '../../util';

const AppGraph = ({ width, height, data }) => {
    const ref = useRef();

    useEffect(() => {
        graph();
    }, [data]);

    const graph = () => {
        const svg = d3.select(ref.current);

        // Margins
        const margin = { top: 50, right: 0, bottom: 80, left: 70 };
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;

        // Scales
        const xScale = d3.scaleBand()
            .domain(data?.map((d) => d['country|name']))
            .range([0, innerWidth])
            .padding(0.2);

        const yScale = d3.scaleLinear()
            .domain([0, d3.max(data, (d) => d?.activeDevices)])
            .range([innerHeight, 0]);

        // Axes
        const xAxis = d3.axisBottom(xScale);
        const yAxis = d3.axisLeft(yScale);

        // Axes Labels
        svg.append("text")
            .attr("x", width / 2)
            .attr("y", height - margin.bottom / 2)
            .attr("text-anchor", "middle")
            .text("Country Names");

        svg.append("text")
            .attr("x", -height / 2)
            .attr("y", margin.left / 2)
            .attr("text-anchor", "middle")
            .attr("transform", "rotate(-90)")
            .text("Active Devices");

        svg.append("g")
            .attr("transform", `translate(${margin.left}, ${innerHeight + margin.top})`)
            .call(xAxis);

        svg.append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`)
            .call(yAxis);

        // Bars
        const selection = svg.selectAll("rect").data(data);

        selection
            .transition().duration(500)
            .attr("height", (d) => innerHeight - yScale(d?.activeDevices))
            .attr("y", (d) => yScale(d?.activeDevices) + margin.top);

        selection
            .enter()
            .append("rect")
            .attr("x", (d) => xScale(d['country|name']) + margin.left)
            .attr("y", (d) => height)
            .attr("width", xScale.bandwidth())
            .attr("height", 0)
            .attr("fill", colors.primaryColor)
            .transition().duration(500)
            .attr("height", (d) => innerHeight - yScale(d?.activeDevices))
            .attr("y", (d) => yScale(d?.activeDevices) + margin.top);

        selection
            .exit()
            .transition().duration(300)
            .attr("y", (d) => height)
            .attr("height", 0)
            .remove();
    };


    return (
        <Box className="chart">
            <Card>
                <svg ref={ref} width={width} height={height}  >
                </svg>
            </Card>
        </Box>
    );
}

export default AppGraph; 

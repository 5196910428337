import { createTheme } from "@mui/material/styles";
import { colors } from "../util/index";
export const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          background: colors.white,
          padding: "20px 30px",
        },
      },
    },

    MuiTypography: {
      defaultProps: {
        fontFamily: "montserrat_mediumregular,Arial",
        letterSpacing: "0",
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: 0,
          "& .MuiInputBase-root": {
            paddingLeft: 0,
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          margin: "0",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: colors.black,
          background: colors.white,
          borderWidth: 1,
          borderColor: "rgba(0, 0, 0, 0.23)",
          borderRadius: 10,
          fontWeight: 500,
          fontSize: 14,
          height: 50,
          boxShadow: "-5px 10px 20px rgba(0, 0, 0, 0.05)",
          "& .MuiOutlinedInput-input": {
            padding: "15px",
            height: "auto",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "solid 1px rgba(0, 0, 0, 0.23)",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          textAlign: "left",
          fontSize: 16,
          fontWeight: "600",
          color: colors.black,
          marginBottom: 10,
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: colors.primaryColor,
          fontSize: 16,
          borderRadius: "5px",
          fontWeight: 700,
          padding: "10px 12px",
          boxShadow: "none",
          fontFamily: "montserrat_mediumregular,Arial",
          "&:hover": {
            background: colors.primary_with_opacity_80,
          },
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.primaryColor,
          fontSize: 14,
          textDecoration: "none",
          fontWeight: 600,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: colors.primaryColor,
          height: 2,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontFamily: "montserrat_mediumregular,Arial",
          color: colors.secondaryColor,
          maxWidth: "initial",
          minWidth: "initial",
          letterSpacing: 0,
          padding: 0,
          "&.Mui-selected": {
            color: colors.primaryColor,
            fontWeight: 700,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: "500",
          fontSize: 14,
          lineHeight: "17px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 14,
          padding: 10,
          fontFamily: "montserrat_mediumregular,Arial",
          "& .MuiSvgIcon-root": {
            width: "20px",
            height: "20px",
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-head": {
            fontSize: 12,
            background: colors.grey_800,
            fontWeight: 700,
            lineHeight: "15px",
            textTransform: "uppercase",
            color: colors.secondaryColor,
            fontFamily: "montserrat_mediumregular,Arial",
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          fontFamily: "montserrat_mediumregular,Arial",
          "& .MuiTablePagination-displayedRows": {
            fontFamily: "montserrat_mediumregular,Arial",
          },
          "& .MuiTablePagination-actions": {
            "& button:hover": {
              color: colors.primaryColor,
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiDialog-paper": {
            fontFamily: "montserrat_mediumregular,Arial",
            margin: 0,
            borderRadius: 10,
            maxHeight: "calc(100% - 50px)",
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          "& .MuiCardHeader-avatar": {
            marginRight: 10,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: "montserrat_mediumregular,Arial",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderWidth: 1,
          borderColor: colors.black,
          // border: `1px solid ${colors.grey_200}`,
          border: "1px solid #DFDFDF",
          borderRadius: "10px",
          marginBottom: "15px",
          "&:last-of-type": {
            borderRadius: "10px",
          },
          "&.Mui-expanded": {
            margin: "0 0 15px 0",
          },
          "&.Mui-expanded:last-of-type": {
            marginBottom: "15px",
          },
          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: "initial!important",
          padding: 15,
          "& .MuiTypography-root": {
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "17px",
            color: colors.black,
          },
          "& .MuiAccordionSummary-content": {
            margin: "0",
            minHeight: "initial",
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "0",
            minHeight: "initial",
            position: "relative",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 15,
          "& .MuiTypography-root": {
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "22px",
            color: colors.black,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          alignItems: "center",
        },
      },
    },
  },

  typography: {
    h1: {
      fontWeight: 700,
      fontSize: 45,
      color: colors.primaryColor,
    },
    h2: {
      fontWeight: 700,
      fontSize: 20,
      color: colors.primaryColor,
      letterSpacing: 0,
    },
    h3: {
      fontWeight: 600,
      fontSize: 18,
      lineHeight: "22px",
      color: colors.primaryColor,
      letterSpacing: 0,
    },
    h5: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "17px",
      color: colors.secondaryColor,
      letterSpacing: 0,
    },
    h6: {
      fontWeight: 700,
      lineHeight: "15px",
      fontSize: 12,
      color: colors.body_1,
    },
    subtitle1: {
      fontWeight: 500,
      color: colors.black,
      fontSize: 16,
    },
    subtitle2: {
      fontWeight: 500,
      lineHeight: "15px",
      fontSize: 12,
      color: colors.black,
    },
    body1: {
      fontWeight: 700,
      lineHeight: "12px",
      fontSize: 10,
      color: colors.body_1,
    },
    body2: {
      fontWeight: 400,
      lineHeight: "17px",
      fontSize: 14,
      color: colors.black,
    },
  },
});

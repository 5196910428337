import React, { Fragment, useContext, useEffect, useState } from "react"
import CloseIcon from "@mui/icons-material/Close";
import {
  CardMedia,
  Checkbox,
  CircularProgress,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { serverTimestamp } from "firebase/firestore"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import Upload from "../../components/Public-icons/upload"
import { AlertMessageContext } from "../../context/AlertMessageProvider"
import { BrandPostingContext } from "../../context/BrandPostProvider"
import { dileverableData } from "../../json-data/diliverablesData"
import { genreData } from "../../json-data/genreData"
import { requirementsData } from "../../json-data/requirementsData"
import { colors } from "../../util"
import { brandStyle } from "../../util/brandStyle"
import { constantStyle } from "../../util/constant"

const CreateBrandPostingModal = ({ isEdit, open, actions, editData, isDraft }) => {
  let maxWidth = "md"
  const [loader, setLoader] = useState()
  const [draftLoader, setDraftLoader] = useState(false)
  const { setShow, setMessage } = useContext(AlertMessageContext)
  const { addBrandData, updateBrandData } = useContext(BrandPostingContext)
  const [path, setPath] = useState()
  const [brandLogo, setBrandLogo] = useState(isEdit || isDraft ? editData.brandLogo : "")
  const [platform, setPlatform] = useState(
    isEdit || isDraft ? editData.platform : []
  )
  const [selectedValue, setSelectedValue] = useState("")
  const [formData, setFormData] = useState({
    brandName: isEdit || isDraft ? editData.brandName : "",
    brandCategory: isEdit || isDraft ? editData.brandCategory : [],
    requiredFollower: isEdit || isDraft ? editData.requiredFollower : {},
    deliverables: isEdit || isDraft ? editData.deliverables : [],
    about: isEdit || isDraft ? editData.about : "",
    PS: isEdit || isDraft ? editData.PS : "",
    what_do_you_get: isEdit || isDraft ? editData.what_do_you_get : "",
    updateAt: serverTimestamp(),
    createdAt: serverTimestamp(),
  });

  const handleClickPlatform = (e) => {
    const value = e.target.value;
    const button = e.target.name;

    const newItem = { key: platform.length, text: value }
    setPlatform([newItem])

    setSelectedValue(button)
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormData((prevState) => ({ ...prevState, [name]: value }))
  }
  const handleCategory = (event) => {
    const name = event.target.name;
    const newCategory = event.target.value.map((categoryName) => ({
      text: categoryName,
      checked: true,
    }))
    setFormData((prevState) => ({ ...prevState, [name]: newCategory }))
  }

  const handleRequirment = (event) => {
    const newRequirmentText = event.target.value;
    const newRequirment = requirementsData.find(
      (requirement) => requirement.text === newRequirmentText
    );
    setFormData((prevState) => ({
      ...prevState,
      requiredFollower: newRequirment,
    }));
  };

  const handleFileChange = (event) => {
    setPath(event.target.files[0]);
    let value = URL.createObjectURL(event.target.files[0]);
    setBrandLogo(value);
  };

  const validationSchema = Yup.object().shape({
    brandName: Yup.string().required("* Brand name is required "),
    about: Yup.string().required("* Description is required"),
    PS: Yup.string().required("* PS is required"),
    what_do_you_get: Yup.string().required("* What do you get is required"),
    brandCategory: Yup.array().min(1, "* Please select at least one category"),
    deliverables: Yup.array().min(1, "* Please select at least one dileverable"),
    brandLogo: Yup.mixed().required("* Brand logo is required"),
    platform: Yup.array().min(1, "* Please select at least one platform"),
    requiredFollower: Yup.object().shape({
      text: Yup.string().required("* Follower is required"),
    })
  });

  const initialValues = {
    brandName: formData.brandName,
    brandCategory: formData.brandCategory,
    requiredFollower: { text: formData.requiredFollower.text },
    deliverables: formData.deliverables,
    about: formData.about,
    PS: formData.PS,
    what_do_you_get: formData.what_do_you_get,
    brandLogo: brandLogo,
    platform: platform,
  };

  const handleSubmit = async (publish) => {
    try {
      setLoader(publish)
      setDraftLoader(!publish)

      // brand posting form data fields  
      let data = {
        brandName: formData.brandName,
        brandCategory: formData.brandCategory,
        requiredFollower: formData.requiredFollower,
        platform: platform,
        deliverables: formData.deliverables,
        about: formData.about,
        PS: formData.PS,
        what_do_you_get: formData.what_do_you_get,
        updateAt: formData.updateAt,
        createdAt: formData.createdAt,
        publish: publish,
        brandLogo: brandLogo,
      };

      // if brand logo include https then it delete brand logo object from data
      if (brandLogo.includes("https")) {
        delete data[brandLogo];
      }

      // update data or add data for brandposting 
      await (isEdit || isDraft
        ? updateBrandData(data, path, editData.id)
        : addBrandData(data, path));

      setTimeout(() => {
        actions();
      }, 200);
    } catch (err) {
      setShow(true);
      setMessage({
        text: isEdit || isDraft ? "No Data Updated" : "No Data Added",
        type: "error",
      });
    } finally {
      setLoader(false);
      setDraftLoader(false);
    }
  };

  useEffect(() => {
    setSelectedValue(platform.length > 0 ? platform[0].text : "");
  }, [platform]);

  return (
    <Fragment>
      <Dialog
        PaperProps={{
          sx: { width: "60%" },
        }}
        maxWidth={maxWidth}
        isEdit
        isDraft
        open={open}
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={() => handleSubmit(true)}
        >
          {({ errors, touched }) => (
            <Form>
              <DialogTitle sx={constantStyle.dialogTitle} variant="h3">
                {isEdit
                  ? "Edit Brand Posting"
                  : isDraft
                    ? "Draft Brand Posting"
                    : "Create Brand Posting"}
                <DialogActions onClick={actions} sx={constantStyle.dialogAction}>
                  <CloseIcon sx={constantStyle.closeIcon} />
                </DialogActions>
              </DialogTitle>

              <DialogContent sx={constantStyle.dialogContent}>
                <Box component="form" className="cardform">
                  <Box className="uploadpicForme">
                    <Box sx={{ width: "50%" }}>
                      <Box className="form-group">
                        <label htmlFor="brandName">Name</label>
                        <TextField
                          id="brandName"
                          fullWidth
                          value={formData.brandName}
                          name="brandName"
                          onChange={handleInputChange}
                          placeholder="Enter Brand Name"
                          required
                        />
                        {errors.brandName && touched.brandName ? (
                          <label style={brandStyle.errorMessage}>
                            {errors.brandName}
                          </label>
                        ) : null}
                      </Box>
                      <Box className="form-group" sx={{ mb: "0!important" }}>
                        <label htmlFor="about">Description</label>
                        <TextField
                          placeholder="Enter Description"
                          multiline
                          rows={4}
                          required
                          fullWidth
                          variant="filled"
                          value={formData.about}
                          name="about"
                          onChange={handleInputChange}
                        />
                      </Box>
                      {errors.about && touched.about ? (
                        <label style={brandStyle.errorMessage}>
                          {errors.about}
                        </label>
                      ) : null}
                    </Box>
                    <Box className="fileselect">
                      <TextField
                        className="filefile"
                        name="Image"
                        required
                        hidden
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                      />

                      {brandLogo !== "" ? (
                        <CardMedia
                          component="img"
                          sx={{ height: 200 }}
                          image={brandLogo}
                          alt="file"
                        />
                      ) : (
                        <Box>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 600,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <Upload />
                            Upload Photo
                          </Typography>
                          <Button sx={brandStyle.imageSelectBtn}>Select</Button>
                          <Typography
                            variant="subtitle2"
                            sx={brandStyle.imageSelectText}
                          >
                            Or drag and drop photo anywhere in this box
                          </Typography>
                        </Box>
                      )}
                      {errors.brandLogo && touched.brandLogo ? (
                        <label style={brandStyle.errorMessage}>
                          {errors.brandLogo}
                        </label>
                      ) : null}
                    </Box>
                  </Box>
                  <Box className="requirements-info">
                    <Box className="form-group">
                      <label htmlFor="brandCategory">Genre</label>
                      <Select
                        fullWidth
                        multiple
                        name="brandCategory"
                        value={formData.brandCategory
                          .filter((category) => category.checked)
                          .map((category) => category.text) || ""}
                        onChange={handleCategory}
                        displayEmpty
                        renderValue={(selected) => selected.length === 0 ? "Select Genre " : selected.join(", ")}
                        MenuProps={{
                          style: {
                            maxHeight: "200px ",
                          },
                        }}
                      >
                        <MenuItem value="" disabled >
                          Select Genre
                        </MenuItem>
                        {genreData.map((category) => (
                          <MenuItem
                            className="selectitem"
                            key={category.text}
                            value={category.text}
                          >
                            <Checkbox
                              checked={formData.brandCategory.some(
                                (selectedCategory) =>
                                  selectedCategory.text === category.text
                              )}
                            />
                            <ListItemText primary={category.text} />
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.brandCategory && touched.brandCategory ? (
                        <label style={brandStyle.errorMessage}>
                          {errors.brandCategory}
                        </label>
                      ) : null}
                    </Box>

                    <Box className="form-group">
                      <label htmlFor="requiredFollower">Requirements</label>
                      <Select
                        required
                        fullWidth
                        name="requiredFollower"
                        value={formData.requiredFollower.text || ""}
                        onChange={handleRequirment}
                        displayEmpty
                      >
                        <MenuItem disabled value="">
                          Select Requirment
                        </MenuItem>
                        {requirementsData.map((item, index) => (
                          <MenuItem key={index} value={item.text}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.requiredFollower?.text && touched.requiredFollower?.text ? (
                        <label style={brandStyle.errorMessage}>{errors.requiredFollower.text}</label>
                      ) : null}
                    </Box>

                    <Box className="form-group">
                      <label htmlFor="platform">Platform</label>
                      <Stack
                        className="socialmedia"
                        gap={2}
                        direction="row"
                        justifyContent={"space-between"}
                      >
                        <Button
                          name="Instagram"
                          selected={selectedValue === "Instagram"}
                          value="Instagram"
                          onClick={handleClickPlatform}
                        >
                          Instagram
                        </Button>

                        <Button
                          name="Youtube"
                          selected={selectedValue === "Youtube"}
                          value="Youtube"
                          onClick={handleClickPlatform}
                        >
                          YouTube
                        </Button>
                      </Stack>
                      {errors.platform && touched.platform ? (
                        <label htmlFor="platform" style={brandStyle.errorMessage}>
                          {errors.platform}
                        </label>
                      ) : null}
                    </Box>



                    <Box className="form-group">
                      <label htmlFor="deliverables">Deliverables</label>
                      <Select
                        fullWidth
                        multiple
                        name="deliverables"
                        value={formData.deliverables
                          .filter((diliverablesOptions) => diliverablesOptions.checked)
                          .map((diliverablesOptions) => diliverablesOptions.text) || ""}
                        onChange={handleCategory}
                        displayEmpty
                        renderValue={(selected) => selected.length === 0 ? "Select deliverables " : selected.join(", ")}
                        MenuProps={{
                          style: {
                            maxHeight: "200px ",
                          },
                        }}
                      >
                        <MenuItem disabled value="">
                          Select diliverables
                        </MenuItem>
                        {dileverableData.map((item) => (
                          <MenuItem
                            className="selectitem"
                            key={item.id}
                            value={item.text}
                          >
                            <Checkbox
                              checked={formData.deliverables.some(
                                (diliverablesOptions) =>
                                  diliverablesOptions.text === item.text
                              )}
                            />
                            <ListItemText primary={item.text} />
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.deliverables && touched.deliverables ? (
                        <label style={brandStyle.errorMessage}>
                          {errors.deliverables}
                        </label>
                      ) : null}
                    </Box>

                    <Box className="form-group" sx={{ mb: "0!important" }}>
                      <label htmlFor="what_do_you_get">What Do You Get</label>
                      <TextField
                        placeholder="Enter what you’ll get"
                        multiline
                        rows={4}
                        fullWidth
                        variant="filled"
                        name="what_do_you_get"
                        value={formData.what_do_you_get}
                        onChange={handleInputChange}
                      />
                      {errors.what_do_you_get && touched.what_do_you_get ? (
                        <label style={brandStyle.errorMessage}>
                          {errors.what_do_you_get}
                        </label>
                      ) : null}
                    </Box>
                    <Box className="form-group" sx={{ mb: "0!important" }}>
                      <label htmlFor="PS">PS</label>
                      <TextField
                        placeholder="Enter PS"
                        multiline
                        rows={4}
                        fullWidth
                        variant="filled"
                        value={formData.PS}
                        name="PS"
                        onChange={handleInputChange}
                        required
                      />
                      {errors.PS && touched.PS ? (
                        <label style={brandStyle.errorMessage}>
                          {errors.PS}
                        </label>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
                <Button
                  disabled={loader}
                  sx={brandStyle.submitBtn}
                  type="submit"
                >
                  {isEdit ? (
                    <Fragment>
                      {loader ? <CircularProgress style={brandStyle.loader} size={24} /> : "Save"}
                    </Fragment>
                  ) : (
                    <Fragment>
                      {loader ? <CircularProgress style={brandStyle.loader} size={24} /> : "Publish"}
                    </Fragment>
                  )}
                </Button>
                {isEdit ? null : (
                  <Button disabled={draftLoader} sx={brandStyle.draftBtn} onClick={() => handleSubmit(false)}>
                    {draftLoader ? (
                      <CircularProgress style={brandStyle.loader} size={24} />
                    ) : (
                      "Save as draft"
                    )}
                  </Button>
                )}
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Fragment>
  );
};

export default CreateBrandPostingModal;
import { ListItemIcon } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderAppBar from "../Header/HeaderAppBar";
import { drawerData } from "./Data";
import "./style.css";
const drawerWidth = 240;
function Drawerleft() {
  const navigator = useNavigate();
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(1);
  const loadData = _.split(window.location.href, "/");
  const [selectedData, setSelectedData] = useState("");

  useEffect(() => {
    const index = _.findIndex(drawerData, function (data) {
      return data.url.replace("/", "").includes(loadData[loadData.length - 1].replace("-details", ""));
    });
  
    // Check if the index is valid before accessing the data
    if (index !== -1 && drawerData[index]) {
      setSelectedData(drawerData[index].text);
      setSelectedIndex(index + 1);
    } else {
      // Handle the case when the index is not found or drawerData[index] is undefined
      setSelectedData("");
      setSelectedIndex(-1);
    }
  }, [loadData, drawerData]);
  const handleListItemClick = (event, id, url, text) => {
    setSelectedData(text);
    setSelectedIndex(id);
    navigator(url);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <HeaderAppBar data={selectedData} />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          className="Drawerleftside"
          sx={{
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
        >
          <Toolbar className="innerlogo">
            <img src="images/Logoinner.svg" alt="Logo" />
          </Toolbar>

          <List>
            {drawerData.map((data, index) => {
              const { id, icon, url, text } = data;
              return (
                <ListItem key={index}>
                  <ListItemButton
                    selected={selectedIndex === id}
                    onClick={(event) =>
                      handleListItemClick(event, id, url, text)
                    }
                    disableRipple
                  >
                    <ListItemIcon>
                      {icon}
                      <ListItemText primary={text} />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Drawer>
      </Box>
    </Box>
  );
}

Drawerleft.propTypes = {
  window: PropTypes.func,
};

export default Drawerleft;

import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { fireStore } from "../firebase";
import { AlertMessageContext } from './AlertMessageProvider';

export const TransactionContext = createContext()

const TransactionProvider = ({ children }) => {
  const { setShow, setMessage } = useContext(AlertMessageContext)
  const [withdrawalRequest, setWithdrawlRequest] = useState([])
  const [collabrations, setCollabrations] = useState([])
  const [loader, setLoader] = useState(false)

  // get withdrawl request data from firebase
  const getWithdrawlRequest = useCallback(async () => {
    try {
      setLoader(true)
      const docRef = collection(fireStore, "WITHDRAWAL_REQUEST");
      const response = await getDocs(docRef);
      const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setWithdrawlRequest(data)
    } catch (err) {
      setShow(true);
      setMessage({ text: "No data found", type: "error" });
    }
    finally {
      setLoader(false)
    }

  }, [setShow, setMessage]);

  // get collabrations data from firebase
  const getCollaborations = useCallback(async (page, rowsPerPage) => {
    try {
      setLoader(true)
      const contractDocRef = collection(fireStore, "CONTRACT");
      const userDocRef = collection(fireStore, "USERS");
      const postDocRef = collection(fireStore, "POSTS");

      const contractChargesResponse = await getDocs(query(contractDocRef, orderBy("createdAt", "desc")));
      const contractChargesData =
        contractChargesResponse?.docs?.map((doc) => ({ ...doc.data(), id: doc.id })) ?? [];

      const allCreatorIds = contractChargesData.map((data) => data.creatorId).filter(Boolean);
      const allCollaboratorIds = contractChargesData.map((data) => data.collaboratorId).filter(Boolean);
      const allPostIds = contractChargesData.map((data) => data.postId).filter(Boolean);

      const startIndex = page * rowsPerPage;

      const batchedCreatorIds = batchArray(allCreatorIds.slice(startIndex, startIndex + rowsPerPage), 10);
      const batchedCollaboratorIds = batchArray(allCollaboratorIds.slice(startIndex, startIndex + rowsPerPage), 10);
      const batchedPostIds = batchArray(allPostIds.slice(startIndex, startIndex + rowsPerPage), 10);

      const [creatorResponses, collaboratorResponses, postResponses] = await Promise.all([
        Promise.all(batchedCreatorIds.map((batch) => getDocs(query(userDocRef, where("id", "in", batch))))),
        Promise.all(batchedCollaboratorIds.map((batch) => getDocs(query(userDocRef, where("id", "in", batch))))),
        Promise.all(batchedPostIds.map((batch) => getDocs(query(postDocRef, where("id", "in", batch))))),
      ]);

      let creatorData = {};
      let collaboratorData = {};
      let postData = {};

      for (let response of creatorResponses) {
        response.docs.forEach((doc) => {
          creatorData[doc.id] = { ...doc.data(), id: doc.id };
        });
      }

      for (let response of collaboratorResponses) {
        response.docs.forEach((doc) => {
          collaboratorData[doc.id] = { ...doc.data(), id: doc.id };
        });
      }

      for (let response of postResponses) {
        response.docs.forEach((doc) => {
          postData[doc.id] = { ...doc.data(), id: doc.id };
        });
      }

      const collaborations = contractChargesData.map((contractData) => {
        const creator = creatorData[contractData.creatorId] ?? {};
        const collaborator = collaboratorData[contractData.collaboratorId] ?? {};
        const post = postData[contractData.postId] ?? {};
        return {
          ...contractData,
          creatorName: creator.displayName,
          creatorPhoto: creator.photo,
          collaboratorName: collaborator.displayName,
          collaboratorPhoto: collaborator.photo,
          type: post.type,
          description: post.description,
          postLink: post.postLink,
          platform: post.platform,
          genre: post.genre,
          requirements: post.requirements,
          gender: post.gender,
          follower: post.follower,
        };
      });

      setCollabrations(collaborations);
    } catch (err) {
      setShow(true);
      setMessage({ text: "No data found", type: "error" });
    }
    finally {
      setLoader(false)
    }
  }, [setShow, setMessage]);


  // split array into batches according to batchSize `batchSize` beacause Firestore queries take 10 array  limit only
  const batchArray = (arr, batchSize) => {
    let batches = [];
    for (let i = 0; i < arr.length; i += batchSize) {
      batches.push(arr.slice(i, i + batchSize));
    }
    return batches;
  };


  // export data to excel file
  const exportFile = (dataToExport, sheetName, filName) => {
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    worksheet['!cols'] = [
      { wpx: 50 },
      { wpx: 150 },
      { wpx: 100 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 200 },
      { wpx: 150 },
      { wpx: 100 },

    ]
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    const buffer = XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });

    const blob = new Blob([buffer], { type: "application/octet-stream" });

    const fileName = filName;
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;
    downloadLink.click();
  }

  // function for to set  formate date  from firebase
  function formatDate(date) {
    return date?.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
  }

  // function for to set  formate time from firebase
  function formatTime(date) {
    return date?.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });
  }

  // function for to set  formate date and time together
  function formatDateTime(date) {
    return `${formatDate(date)} & ${formatTime(date)}`;
  }
  useEffect(() => {
    getWithdrawlRequest()
  }, [getWithdrawlRequest])

  return (
    <TransactionContext.Provider
      value={{
        withdrawalRequest,
        exportFile,
        collabrations,
        formatDateTime,
        getCollaborations,
        loader,
      }}>
      {children}
    </TransactionContext.Provider>
  )
}

export default TransactionProvider
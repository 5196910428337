import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Avatar, CardHeader, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import { slice } from "lodash";
import { useContext, useState } from "react";
import { GroomContext } from "../../context/GroomContentProvider";
import { colors } from "../../util";
import { groomStyle } from "../../util/groomStyle";
import { columns } from "./Data";
import GroomYourContent_Modal from "./GroomYourContent-Modal";

export default function GroomYourContent() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { groomData } = useContext(GroomContext);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [open, setOpen] = useState(false);
  const handleclose = () => {
    setOpen(false);
  };

  const getRowCell = (column, value, rowData) => {
    switch (column.id) {
      case "personName":
        return (
          <TableCell key={column.id} align={column.align}>
            <CardHeader
              sx={{ padding: 0, marginRight: "0" }}
              title={value}
              avatar={
                <Avatar
                  sx={{
                    height: 17,
                    width: 17,
                    marginRight: "0",
                  }}
                  alt={value.name}
                  src={value.profilePhoto}
                />
              }
            />
          </TableCell>
        );
      case "icon":
        return (
          <TableCell key={column.id} align={column.align}>
            <RemoveRedEyeIcon
              sx={{ color: colors.primaryColor, cursor: "pointer" }}
              onClick={() => setOpen(rowData)}
            />
          </TableCell>
        );
      default:
        return (
          <TableCell key={column.id} align={column.align}>
            {column.format && typeof value === "number"
              ? column.format(value)
              : value}
          </TableCell>
        );
    }
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const rowsToShow = slice(groomData, startIndex, endIndex);

  return (
    <Box>
      <Paper sx={groomStyle.paper}>
        <TableContainer sx={{ overflowY: "auto" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {groomData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="left">
                    No data found.
                  </TableCell>
                </TableRow>
              ) : (
                rowsToShow.map((rowData) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={rowData.id}>
                    {columns.map((column) => {
                      const value = rowData[column.id];
                      const cellData = getRowCell(column, value, rowData);
                      return cellData;
                    })}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <TablePagination
        rowsPerPageOptions={[]}
        component="article"
        count={groomData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          native: false,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <GroomYourContent_Modal open={open} actions={handleclose} />
    </Box>
  );
}

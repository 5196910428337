import React from "react";

export default function DashboardIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_22_5)">
        <path
          d="M11.6771 0H17.6146C18.3786 0 19 0.621437 19 1.38537V4.94787C19 5.71194 18.3786 6.33338 17.6146 6.33338H11.6771C10.9131 6.33338 10.2916 5.71194 10.2916 4.94787V1.38537C10.2916 0.621437 10.9131 0 11.6771 0Z"
          fill="#"
        />
        <path
          d="M11.6771 7.91663H17.6146C18.3786 7.91663 19 8.53806 19 9.30214V17.6146C19 18.3786 18.3786 19 17.6146 19H11.6771C10.9131 19 10.2916 18.3786 10.2916 17.6146V9.30214C10.2916 8.53806 10.9131 7.91663 11.6771 7.91663Z"
          fill="#"
        />
        <path
          d="M1.38538 12.6666H7.32288C8.08695 12.6666 8.70839 13.2881 8.70839 14.0521V17.6146C8.70839 18.3786 8.08695 19 7.32288 19H1.38538C0.621445 19 7.62939e-06 18.3786 7.62939e-06 17.6146V14.0521C7.62939e-06 13.2881 0.621445 12.6666 1.38538 12.6666Z"
          fill="#"
        />
        <path
          d="M1.38538 0H7.32288C8.08695 0 8.70839 0.621437 8.70839 1.38537V9.69787C8.70839 10.4619 8.08695 11.0834 7.32288 11.0834H1.38538C0.621445 11.0834 7.62939e-06 10.4619 7.62939e-06 9.69787V1.38537C7.62939e-06 0.621437 0.621445 0 1.38538 0V0Z"
          fill="#"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_5">
          <rect
            width="19"
            height="19"
            fill="white"
            transform="matrix(-1 0 0 1 19 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

import NotificationsIcon from "@mui/icons-material/Notifications"
import SearchIcon from "@mui/icons-material/Search"
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded"
import { Avatar, Paper, Typography } from "@mui/material"
import Badge from "@mui/material/Badge"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import React, { Fragment } from "react"
import AlertDialog from "./AlertDialog"

export default function HeaderAppBar({ data }) {
  return (
    <Fragment>
      <Box>
        <Typography variant="h2" noWrap component="div">
          {data}
        </Typography>
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "flex", justifyContent: "space-between" },
        }}
      >
        <Paper elevation={0} className="toolbarmain">
          <IconButton disableRipple>
            <SearchIcon />
          </IconButton>
        </Paper>
        <Paper elevation={0} className="toolbarmain">
          <IconButton aria-label="show 0 new notifications" disableRipple>
            <Badge badgeContent={0}>
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Paper>

        <Paper elevation={0} className="toolbarmain">
          <IconButton disableRipple>
            <SettingsRoundedIcon />
          </IconButton>
        </Paper>

        <AlertDialog />

        <Avatar
          alt="avatar"
          className="avtarrounded"
          sx={{
            width: 32,
            height: 32,
            border: "1px solid #E7E8FC",
            borderRadius: 5,
          }}
          src="https://picsum.photos/40"
        />
      </Box>
    </Fragment>
  )
}

export const genreData = [
    {
        id: 1,
        text: "Design/Art",
    },
    {
        id: 2,
        text: "Cooking",
    },
    {
        id: 3,
        text: "DIY",
    },
    {
        id: 4,
        text: "Sports",
    },
    {
        id: 5,
        text: "Beauty",
    },
    {
        id: 6,
        text: "Tech",
    },
    {
        id: 7,
        text: "Music",
    },
    {
        id: 8,
        text: "Gaming",
    },
    {
        id: 9,
        text: "Fashion",
    },
    {
        id: 10,
        text: "Health & Fitness",
    },
    {
        id: 11,
        text: "Daily Vloggers",
    },
    {
        id: 12,
        text: "Food",
    },
    {
        id: 13,
        text: "Travel",
    },
    {
        id: 14,
        text: "Business",
    },
    {
        id: 15,
        text: "Other",
    },
]
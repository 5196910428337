import { CircularProgress } from "@mui/material"
import React from "react"
import { colors } from "../../util"
import { constantStyle } from "../../util/constant"

export const Loader = () => {
  return (
    <div style={constantStyle.loader} >
      <CircularProgress
        size={"30px"}
        style={{ color: colors.primaryColor }}
      />
    </div>
  )
}

import React from "react"

export default function Marketing() {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5912 60.6423L39.1828 68.2339C40.0956 69.1467 40.0956 70.6356 39.1828 71.5484L36.0508 74.6804C35.1379 75.5932 33.649 75.5932 32.7362 74.6804L23.3631 65.3073L30.5726 61.1448C30.9114 60.9748 31.2509 60.8073 31.5912 60.6423V60.6423ZM77.1426 31.8756C78.1259 32.1953 78.6639 33.2517 78.3442 34.235C78.0244 35.2182 76.9681 35.7562 75.9848 35.4365L69.1665 33.2212C68.1833 32.9015 67.6453 31.8451 67.965 30.8618C68.2847 29.8785 69.3411 29.3406 70.3244 29.6604L77.1426 31.8756ZM69.4209 21.8462C68.524 22.364 67.3773 22.0567 66.8597 21.1598C66.3419 20.2631 66.6492 19.1162 67.5459 18.5985L73.7045 15.0428C74.6014 14.525 75.7481 14.8323 76.2658 15.729C76.7836 16.6259 76.4762 17.7726 75.5795 18.2904L69.4209 21.8462ZM59.0583 6.1187C59.2729 5.10729 60.267 4.46151 61.2786 4.6762C62.29 4.89089 62.9358 5.88495 62.7211 6.89651L61.2303 13.909C61.0156 14.9204 60.0215 15.5662 59.0101 15.3515C57.9987 15.1368 57.353 14.1428 57.5676 13.1314L59.0583 6.1187ZM39.4597 8.26886C37.6686 9.30292 37.049 11.6148 38.0833 13.4059L58.6369 49.0059C59.6711 50.7971 61.9828 51.4164 63.774 50.3823C65.5651 49.3482 66.1845 47.0364 65.1505 45.2453L58.1581 33.1343L59.072 32.6067C62.2575 30.7674 63.359 26.6565 61.52 23.4709C59.6808 20.2854 55.5697 19.1839 52.3842 21.0229L51.4703 21.5506L44.5967 9.64511C43.5626 7.85432 41.2509 7.23479 39.4597 8.26886V8.26886ZM4.5506 56.3623L6.18857 59.1995C8.97342 64.0229 15.1983 65.6909 20.0219 62.906L27.175 58.7762L13.7723 35.5621L6.6192 39.692C1.79576 42.4768 0.127791 48.7017 2.91264 53.5251L4.5506 56.3623ZM16.9425 33.5854C24.7025 28.2557 30.6764 22.3273 35.3106 16.1037L54.7955 49.8526C47.0886 50.754 38.9673 52.9635 30.4717 57.019L16.9425 33.5854Z"
        fill="#C5C5C5"
      />
    </svg>
  )
}

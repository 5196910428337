import PostIcon from "../Public-icons/post";
import { BrandPostingIcon } from "./icons/BrandPostingIcon";
import { CustomerSupportIcon } from "./icons/CustomerSupportIcon";
import DashboardIcon from "./icons/DashboardIcon";
import GroomContent from "./icons/GroomContent";
import { TransactionsIcon } from "./icons/TransactionsIcon";
import { UserManagementIcon } from "./icons/UserManagementIcon";

export const drawerData = [
  {
    id: 1,
    text: "Overview",
    url: "/",
    icon: <DashboardIcon />,
  },

  {
    id: 2,
    text: "Brand Posting",
    url: "/brand-posting",
    icon: <BrandPostingIcon />,
  },

  {
    id: 3,
    text: "Groom Your Content",
    url: "/groom-your-content",
    icon: <GroomContent />,
  },
  {
    id: 4,
    text: "Transactions",
    url: "/transaction",
    icon: <TransactionsIcon />,
  },

  {
    id: 5,
    text: "User Management",
    url: "/user-management",
    icon: <UserManagementIcon />,
  },
  {
    id: 6,
    text: "Posts",
    url: "/posts",
    icon: <PostIcon />,
  },

  {
    id: 7,
    text: "Customer Support",
    url: "/customer-support",
    icon: <CustomerSupportIcon />,
  },
];

import { colors } from ".";

export const constantStyle = {
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px",
    borderBottom: "solid 1px #DFDFDF",
    marginBottom: "15px",
  },
  dialogAction: {
    p: 0,
    background: colors.primaryColor,
    borderRadius: "50%",
    width: 22,
    height: 22,
    justifyContent: "center",
    cursor: "pointer",
  },
  dialogContent: { p: "20px" },
  closeIcon: { color: colors.white, fontSize: 18 },
  dialogMainBox: { mt: 0 },
  dialogInnerBox: { width: "160px", mr: "40px" },
  filterBtn: {
    background: colors.white,
    border: "1px solid #E7E8FC",
    "&:hover": {
      background: colors.white,
    },
  },
  letterSpacing: { letterSpacing: "0.3em" },
  tabLabel: { p: 0 },
};

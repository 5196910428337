import React, { createContext, useState } from "react"
export const AlertMessageContext = createContext()

const AlertMessageProvider = ({ children }) => {
  const [show, setShow] = useState(false)

  const [message, setMessage] = useState({ text: "", type: "" })

  const handleClose = () => {
    setShow(false)
  }
  return (
    <AlertMessageContext.Provider
      value={{
        show,
        setShow,
        message,
        setMessage,
        handleClose,
      }}
    >
      {children}
    </AlertMessageContext.Provider>
  )
}

export default AlertMessageProvider

export const columns = [
    { id: "personName", label: "Creator Name" },
    {
        id: "phone",
        label: "Phone no.",
        align: "left",
        minWidth: 100,
    },
    {
        id: "typeOfProfessional",
        label: "type of professional",
        align: "left",
    },
    {
        id: "createdAt",
        label: "Date & Time",
        align: "left",
    },
    {
        id: "icon",
        minWidth: 20,
        align: "center",
    },
]



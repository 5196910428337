import React from "react"

export default function Collaborationsicon() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.1" cx="25" cy="25" r="25" fill="#E2374B" />
      <g clipPath="url(#clip0_470_405)">
        <path
          d="M34.7893 28.7029L32.8943 26.8077L28.9742 30.7279C28.2744 31.4276 27.3961 31.8839 26.4421 32.0546L28.9399 34.5523C29.1686 34.7812 29.5397 34.7812 29.7684 34.5523L34.7893 29.5316C35.0182 29.3027 35.0182 28.9316 34.7893 28.7029Z"
          fill="#E2374B"
        />
        <path
          d="M32.1224 25.9224C32.7829 25.262 32.7831 24.1873 32.1233 23.5265C32.1225 23.5257 32.1217 23.5249 32.121 23.5242C31.9577 23.3611 31.767 23.2353 31.5593 23.1513C31.7394 22.6958 31.7774 21.9255 31.1809 21.3289C31.1794 21.3274 31.1778 21.3257 31.1762 21.3242C31.0137 21.1627 30.8241 21.0382 30.6178 20.9546C30.7979 20.4994 30.836 19.729 30.2399 19.1327C30.2394 19.1322 30.2391 19.1319 30.2388 19.1316C30.0754 18.9682 29.8845 18.8422 29.6765 18.7581C29.8566 18.3026 29.8946 17.5322 29.2981 16.9356C29.298 16.9356 29.298 16.9354 29.2978 16.9354C29.2976 16.9353 29.2975 16.9351 29.2973 16.935C28.6365 16.2741 27.5613 16.2741 26.9005 16.935L25.7895 18.0458L26.3576 18.6139C27.3042 19.5606 27.3042 21.1008 26.3577 22.0474C26.0391 22.3659 25.6477 22.5847 25.2211 22.6889C25.119 23.1049 24.906 23.4992 24.5815 23.8236C24.2633 24.1417 23.8725 24.3604 23.4464 24.4647C23.3421 24.8908 23.1233 25.2817 22.8053 25.5997C22.4872 25.9178 22.0964 26.1365 21.6702 26.2409C21.566 26.6669 21.3472 27.0578 21.0292 27.376C20.5707 27.8343 19.9609 28.087 19.3124 28.087C18.6639 28.087 18.0542 27.8345 17.5957 27.3758L17.0276 26.8077L15.1326 28.7029C14.9037 28.9317 14.9037 29.3027 15.1326 29.5316L20.1534 34.5523C20.3822 34.7812 20.7532 34.7811 20.9821 34.5523L24.5761 30.9584H25.5887C26.5546 30.9584 27.4626 30.5821 28.1456 29.8993L32.1224 25.9224Z"
          fill="#E2374B"
        />
        <path
          d="M18.4244 26.5473C18.9148 27.0379 19.7101 27.0379 20.2005 26.5473C20.6911 26.0569 20.6911 25.2617 20.2005 24.7712C20.6911 25.2617 21.4862 25.2617 21.9768 24.7712C22.4672 24.2808 22.4672 23.4855 21.9768 22.995C22.4672 23.4855 23.2625 23.4855 23.7529 22.995C24.2433 22.5046 24.2433 21.7093 23.7529 21.2189C24.2433 21.7093 25.0386 21.7093 25.529 21.2189C26.0196 20.7284 26.0196 19.9332 25.529 19.4426L23.0248 16.9384C22.5344 16.448 21.7391 16.448 21.2486 16.9384C20.7582 17.4288 20.7582 18.2241 21.2486 18.7145L21.5624 19.0284C21.0719 18.5378 20.2767 18.5378 19.7862 19.0284C19.2958 19.5188 19.2958 20.3141 19.7862 20.8045L20.4138 21.4321C19.9234 20.9417 19.1281 20.9417 18.6377 21.4321C18.1471 21.9225 18.1471 22.7178 18.6377 23.2082L19.2653 23.836C18.7747 23.3454 17.9796 23.3454 17.489 23.836C16.9986 24.3264 16.9986 25.1215 17.489 25.6121L18.4244 26.5473Z"
          fill="#E2374B"
        />
        <path
          d="M26.0718 16.1062C26.1584 16.0196 26.2502 15.9399 26.3455 15.8659L26.0028 15.5232C25.8246 15.3448 25.5519 15.3007 25.3265 15.4133L23.8803 16.1365L24.9609 17.2172L26.0718 16.1062Z"
          fill="#E2374B"
        />
      </g>
      <defs>
        <clipPath id="clip0_470_405">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(15 15)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

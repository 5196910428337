import { colors } from ".";

export const dashboardStyle = {
    textStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: colors.primaryColor,
        marginBottom: "10px",
    },
    linkStyle: {
        marginLeft: 5,
        color: colors.primaryColor,
        textDecoration: "underline",
        cursor: "pointer",
        fontSize: "14px",
        fontWeight: "bold",

    }
}

import { colors } from ".";

export const ErrorMessage = {
  brandName: "Please enter Brand Name",
};

export const brandStyle = {
  subTitle1: {
    fontWeight: 600,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
  },
  selectBtn: {
    padding: "10px 24px",
    lineHeight: "17px",
    color: colors.white,
    borderRadius: "5px",
    letterSpacing: "0.3em",
    mt: "10px",
    mb: "10px",
  },
  draftBtn: {
    letterSpacing: "0.3em",
    lineHeight: "17px",
    background: colors.secondaryColor,
    color: colors.white,
    p: "12px 20px",
    mt: "20px",
    ml: "20px",
    width: "233px",
  },
  imageSelectBtn: {
    padding: "10px 24px",
    lineHeight: "17px",
    color: colors.white,
    borderRadius: "5px",
    letterSpacing: "0.3em",
    mt: "10px",
    mb: "10px",
    marginLeft: "110px",
  },
  imageSelectText: {
    color: colors.tabHeadTitle,
    width: "50%",
    textAlign: "center",
    marginLeft: "90px",
  },
  submitBtn: {
    letterSpacing: "0.3em",
    lineHeight: "17px",
    background: colors.primaryColor,
    color: colors.white,
    p: "12px 20px",
    mt: "20px",
    height: "40px",
    width: "150px",
  },
  errorMessage: {
    color: "red",
    fontSize: 12,
    fontWeight: 200,
  },
  loader: {
    color: colors.white,
  },
  Boxsiz: {
    maxWidth: "25%",
    pr: "80px",
    borderRight: "solid 1px #DFDFDF",
  },
  cardDetailsMainBox: {
    display: "flex",
    flexFlow: "wrap",
    justifyContent: "space-between",
  },
  categoryBox: {
    display: "flex",
    flexFlow: "wrap",
  },
  genreCategory: {
    width: { md: "45%", lg: "48%", xl: "54%" },
    flexFlow: "wrap",
    display: "flex",
  },
  categorypic: {
    width: { md: "138px", lg: "145px", xl: "158px" },
    height: { md: "138px", lg: "145px", xl: "158px" },
  },
  link: {
    gap: "10px",
    fontSize: "14px",
    lineHeight: "17px",
    color: colors.black,
  },
  brandPostingMain: {
    borderBottom: 1,
    borderColor: "divider",
    display: "inline-block",
    position: "relative",
    zIndex: 1,
    mt: "20px",
  },
  brandCardData: { display: "flex", flexDirection: "column" },
  brandPostDetailPaper: {
    boxShadow: "-5px 10px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "10px",
    border: "1px solid #DFDFDF",
  },
  backButton: {
    marginBottom: 20,
    borderRadius: 5,
    height: 37,
    width: 102,
    letterSpacing: "0.3em",
    fontSize: "14px",
  },
  arrowBack: { marginRight: 10, width: 18, height: 20 },
};

import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import React, { Fragment, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import Searchbar from "../../components/BrandpostingData/searchbar";
import NoPost from "../../components/Public-icons/noPosts";
import { PostContext } from "../../context/PostProvider";
import { brandStyle } from "../../util/brandStyle";
import { colors } from "../../util";

const Posts = () => {
  const { post, searchText, setSearchText } = useContext(PostContext);

  // Function to render card content based on the "type"
  const usersPostData = (postData) => {
    switch (postData.type) {
      case "Create with Creators":
        return (
          <Fragment>
            <CardContent>
              <Typography variant="body1">Budget</Typography>
              <Typography variant="subtitle2">{postData.budget}</Typography>
            </CardContent>
            {postData.location && (
              <CardContent>
                <Typography variant="body1">Location</Typography>
                <Typography variant="subtitle2">{postData.location}</Typography>
              </CardContent>
            )}
            <CardContent>
              <Typography variant="body1">Status</Typography>
              <Typography variant="subtitle2">{postData.status}</Typography>
            </CardContent>
            <CardContent>
              <Typography variant="body1">Topic</Typography>
              <Typography variant="subtitle2">{postData.topic}</Typography>
            </CardContent>
            <CardContent>
              <Typography variant="body1">Connect On</Typography>
              <Typography variant="subtitle2">{postData.platform}</Typography>
            </CardContent>
            <CardContent>
              <Typography variant="body1">Created At</Typography>
              <Typography variant="subtitle2">{postData.createdAt}</Typography>
            </CardContent>
            <CardContent>
              <Typography variant="body1">Description</Typography>
              <Typography variant="subtitle2"
               sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
              }}
              >
                {postData.description}
              </Typography>
            </CardContent>
          </Fragment>
        );
      case "Give Shoutout":
      case "Shoutout for Shoutout":
        return (
          <Fragment>
            {postData.type === "Give Shoutout" && (
            <CardContent>
              <Typography variant="body1">Budget</Typography>
              <Typography variant="subtitle2">{postData.budget}</Typography>
            </CardContent>
            )}
            <CardContent>
              <Typography variant="body1">Completion Time</Typography>
              <Typography variant="subtitle2">
                {postData.completion_time}
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="body1">Get Shoutout On</Typography>
              <Typography variant="subtitle2">{postData.platform}</Typography>
            </CardContent>
            <CardContent>
              <Typography variant="body1">Post Link</Typography>
              <Typography variant="subtitle2">{postData.postLink}</Typography>
            </CardContent>
            <CardContent>
              <Typography variant="body1">Type</Typography>
              <Typography variant="subtitle2">{postData.type}</Typography>
            </CardContent>
            <CardContent>
              <Typography variant="body1">Created At</Typography>
              <Typography variant="subtitle2">{postData.createdAt}</Typography>
            </CardContent>
            <CardContent>
              <Typography variant="body1">Description</Typography>
              <Typography variant="subtitle2"
               sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
              }}
              >
                {postData.description}
              </Typography>
            </CardContent>
          </Fragment>
        );

      case "Groom your Content":
        return (
          <Fragment>
            <CardContent>
              <Typography variant="body1">Budget</Typography>
              <Typography variant="subtitle2">{postData.budget}</Typography>
            </CardContent>

            {postData.location && (
              <CardContent>
                <Typography variant="body1">Location</Typography>
                <Typography variant="subtitle2">{postData.location}</Typography>
              </CardContent>
            )}

            <CardContent>
              <Typography variant="body1">Status</Typography>
              <Typography variant="subtitle2">{postData.status}</Typography>
            </CardContent>
            <CardContent>
              <Typography variant="body1">Type of professional</Typography>
              <Typography variant="subtitle2">
                {postData.typeOfProfessional}
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="body1">Created At</Typography>
              <Typography variant="subtitle2">{postData.createdAt}</Typography>
            </CardContent>
            <CardContent>
              <Typography variant="body1">Description</Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {postData.description}
              </Typography>
            </CardContent>
          </Fragment>
        );
      default:
        return null;
    }
  };

  const memoizedUsersPostData = useMemo(() => usersPostData, []);

  return (
    <Box className="posts-main">
      <Searchbar searchText={searchText} setSearchText={setSearchText} />
      <Typography variant="h4">
        Posts: <strong>{post.length}</strong>
      </Typography>
      {post.length === 0 ? (
        <div className="no-data">
          <NoPost />
          <Typography variant="h3" sx={{ color: colors.secondaryColor }}>
            No posts to display
          </Typography>
        </div>
      ) : (
        <Box className="brandposting-crds cards-page">
          {post.map((postData, index) => (
            <Card className="postcards" key={index}>
              <Box>
                <Link
                  to={`/posts-details/${postData.id}`}
                  state={{ postDetails: postData }}
                >
                  <Box sx={brandStyle.brandCardData}>
                    {memoizedUsersPostData(postData)}
                    {postData.type === "Create with Creators" &&
                      postData.image && (
                        <CardMedia
                          sx={brandStyle.categorypic}
                          component="img"
                          image={postData.image}
                          alt="Brand Logo"
                        />
                      )}
                  </Box>
                </Link>
              </Box>
            </Card>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Posts;

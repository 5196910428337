import React from "react";

export const BrandPostingIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50292 14.4026L9.30591 16.2056C9.52271 16.4223 9.52271 16.776 9.30591 16.9928L8.56206 17.7366C8.34526 17.9534 7.99165 17.9534 7.77485 17.7366L5.54874 15.5105L7.261 14.5219C7.34145 14.4815 7.42209 14.4417 7.50292 14.4026ZM18.3214 7.57046C18.5549 7.64639 18.6827 7.89729 18.6067 8.13082C18.5308 8.36435 18.2799 8.49211 18.0464 8.41619L16.4271 7.89005C16.1935 7.81412 16.0658 7.56323 16.1417 7.3297C16.2176 7.09617 16.4685 6.9684 16.702 7.04436L18.3214 7.57046ZM16.4875 5.18849C16.2745 5.31147 16.0021 5.23847 15.8792 5.02547C15.7562 4.8125 15.8292 4.54011 16.0422 4.41717L17.5048 3.57267C17.7178 3.44969 17.9902 3.52269 18.1131 3.73566C18.2361 3.94866 18.1631 4.22101 17.9501 4.34399L16.4875 5.18849ZM14.0263 1.45321C14.0773 1.213 14.3134 1.05962 14.5537 1.11061C14.7939 1.1616 14.9472 1.39769 14.8963 1.63794L14.5422 3.30341C14.4912 3.54361 14.2551 3.69699 14.0149 3.646C13.7747 3.59501 13.6213 3.35892 13.6723 3.11871L14.0263 1.45321ZM9.37167 1.96387C8.94629 2.20946 8.79915 2.75853 9.04477 3.18391L13.9263 11.6389C14.1719 12.0643 14.7209 12.2114 15.1463 11.9658C15.5717 11.7202 15.7188 11.1711 15.4732 10.7458L13.8126 7.86942L14.0296 7.7441C14.7862 7.30728 15.0478 6.33094 14.611 5.57435C14.1742 4.8178 13.1978 4.55618 12.4412 4.99296L12.2242 5.11828L10.5917 2.29073C10.3461 1.86542 9.79709 1.71828 9.37167 1.96387ZM1.08077 13.3861L1.46979 14.0599C2.13119 15.2055 3.60959 15.6016 4.75519 14.9402L6.45406 13.9594L3.27093 8.44602L1.57206 9.42686C0.426493 10.0883 0.0303504 11.5667 0.691751 12.7122L1.08077 13.3861ZM4.02384 7.97655C5.86684 6.71075 7.28564 5.30275 8.38627 3.82464L13.0139 11.84C11.1835 12.0541 9.25474 12.5789 7.23703 13.542L4.02384 7.97655Z"
        fill="#"
      />
    </svg>
  );
};

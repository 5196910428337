import React from "react";

export default function NoPost() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="80"
      height="80"
      fill="#999"
    >
      <path
        class="cls-1"
        d="M358.4,204.8H256a12.8,12.8,0,0,0,0,25.6H358.4a12.8,12.8,0,1,0,0-25.6Z"
      />
      <path
        class="cls-1"
        d="M211.9,181.35a12.8,12.8,0,0,0-17.75,3.55l-19.33,29-15.49-7.75a12.8,12.8,0,0,0-11.45,22.9l25.6,12.8a12.8,12.8,0,0,0,16.38-4.35l25.6-38.4A12.8,12.8,0,0,0,211.9,181.35Z"
      />
      <path
        class="cls-1"
        d="M358.4,294.4H256a12.8,12.8,0,0,0,0,25.6H358.4a12.8,12.8,0,0,0,0-25.6Z"
      />
      <path
        class="cls-1"
        d="M211.9,270.95a12.8,12.8,0,0,0-17.75,3.55l-19.33,29-15.49-7.75a12.8,12.8,0,0,0-11.45,22.9l25.6,12.8a12.8,12.8,0,0,0,16.38-4.35l25.6-38.4A12.8,12.8,0,0,0,211.9,270.95Z"
      />
      <path
        class="cls-1"
        d="M358.4,384H256a12.8,12.8,0,0,0,0,25.6H358.4a12.8,12.8,0,0,0,0-25.6Z"
      />
      <path
        class="cls-1"
        d="M211.9,360.55a12.8,12.8,0,0,0-17.75,3.55l-19.33,29-15.49-7.75a12.8,12.8,0,0,0-11.45,22.9l25.6,12.8a12.8,12.8,0,0,0,16.38-4.35l25.6-38.4A12.8,12.8,0,0,0,211.9,360.55Z"
      />
      <path
        class="cls-1"
        d="M396.8,38.4H339A31.89,31.89,0,0,0,313.6,25.6H292.17a38.35,38.35,0,0,0-72.34,0H198.4A31.89,31.89,0,0,0,173,38.4H115.2a64.19,64.19,0,0,0-64,64V448a64.19,64.19,0,0,0,64,64H396.8a64.19,64.19,0,0,0,64-64V102.4A64.19,64.19,0,0,0,396.8,38.4ZM256,25.6a12.8,12.8,0,1,1-12.8,12.8A12.81,12.81,0,0,1,256,25.6Zm-64,32a6.49,6.49,0,0,1,6.4-6.4h21.43a38.35,38.35,0,0,0,72.34,0H313.6a6.49,6.49,0,0,1,6.4,6.4V96a6.49,6.49,0,0,1-6.4,6.4H198.4A6.49,6.49,0,0,1,192,96ZM435.2,448a38.44,38.44,0,0,1-38.4,38.4H115.2A38.44,38.44,0,0,1,76.8,448V102.4A38.44,38.44,0,0,1,115.2,64h51.2V96a32.09,32.09,0,0,0,32,32H313.6a32.09,32.09,0,0,0,32-32V64h51.2a38.44,38.44,0,0,1,38.4,38.4Z"
      />
    </svg>
  );
}

import React from "react"

export default function Edit() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#E2374B" />
      <g clipPath="url(#clip0_319_883)">
        <path
          d="M16.6458 10.3514L8.94276 18.055C8.904 18.0939 8.87602 18.1429 8.8627 18.1957L8.00889 21.6227C7.98335 21.7258 8.01367 21.8355 8.08895 21.9108C8.14592 21.9678 8.22354 21.9993 8.30293 21.9993C8.32725 21.9993 8.35212 21.9963 8.37633 21.9902L11.8033 21.1363C11.8567 21.123 11.9053 21.0951 11.944 21.0564L19.6477 13.3534L16.6458 10.3514Z"
          fill="white"
        />
        <path
          d="M21.5561 9.30111L20.6986 8.44365C20.1255 7.87056 19.1267 7.87111 18.5543 8.44365L17.5039 9.494L20.5057 12.4958L21.5561 11.4455C21.8423 11.1593 22 10.7784 22 10.3733C22 9.96826 21.8423 9.58738 21.5561 9.30111Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_319_883">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

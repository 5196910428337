import CloseIcon from "@mui/icons-material/Close"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material"
import { Box } from "@mui/system"
import React, { useContext, useState } from "react"
import { FaqContext } from "../../context/FaqProvider"
import { colors } from "../../util"
import { constantStyle } from "../../util/constant"
import { customerStyle } from "../../util/cutomerStyle"

export default function AccordionsCreatemodal({ open, action }) {
  const [formData, setFormData] = useState({
    ques: "",
    answer: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { addFaqData } = useContext(FaqContext);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (formData.ques && formData.answer) {
      const data = {
        ques: formData.ques,
        answer: formData.answer,
      };
      await addFaqData(data);
      setTimeout(() => {
        setLoading(false);
        action();
      }, 500);

    } else {
      setLoading(false);
      setErrors({
        ques: !formData.ques ?
          'Question is required' : '',
        answer: !formData.answer ?
          'Answer is required' : ''
      });
    }
  }

  return (
    <Dialog maxWidth={"md"} open={open} PaperProps={{ sx: "width:44%" }}>
      <DialogTitle sx={constantStyle.dialogTitle} variant="h3">
        Create New FAQ
        <DialogActions onClick={action} sx={constantStyle.dialogAction}>
          <CloseIcon sx={constantStyle.closeIcon} />
        </DialogActions>
      </DialogTitle>

      <DialogContent sx={constantStyle.dialogContent}>
        <Box noValidate component="form" className="cardform">
          <Box className="uploadpicForme">
            <Box sx={{ width: "100%" }}>
              <Box className="form-group">
                <label>Question</label>
                <TextField
                  fullWidth
                  placeholder="Enter Question"
                  name="ques"
                  value={formData.ques}
                  onChange={handleChange}
                  error={Boolean(errors.ques)}
                  helperText={errors.ques}
                />
              </Box>
              <Box className="form-group" sx={{ mb: "0!important" }}>
                <label>Answer</label>
                <TextField
                  name="answer"
                  value={formData.answer}
                  onChange={handleChange}
                  placeholder="Enter Answer"
                  multiline
                  rows={4}
                  fullWidth
                  variant="filled"
                  error={Boolean(errors.answer)}
                  helperText={errors.answer}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Button
          disabled={loading}
          sx={customerStyle.submitBtn}
          onClick={handleSubmit}
        >
          {loading ? (
            <CircularProgress size={20} style={{ color: colors.white }} />
          ) : (
            "create"
          )}
        </Button>
      </DialogContent>
    </Dialog>
  )
}

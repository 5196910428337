import { ArrowBack, YouTube } from "@mui/icons-material";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import { Avatar, Button, CardHeader, Paper } from "@mui/material";

import Table from "@mui/material/Table";

import TableBody from "@mui/material/TableBody";

import TableCell from "@mui/material/TableCell";

import TableContainer from "@mui/material/TableContainer";

import TableHead from "@mui/material/TableHead";

import TablePagination from "@mui/material/TablePagination";

import TableRow from "@mui/material/TableRow";

import { Box } from "@mui/system";

import React, { useContext, useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router";

import BrandpostingDetailsModal from "../../components/brand-postingDetails-Modal";

import Carddetails from "../../components/brand-postingDetails-Modal/Carddetails";

import Instagram from "../../components/Public-icons/instagram";

import { BrandPostingContext } from "../../context/BrandPostProvider";

import { colors } from "../../util";

import { brandStyle } from "../../util/brandStyle";

import { columns } from "./Data";

import "./style.css";

import { AlertMessageContext } from "../../context/AlertMessageProvider";

import * as clipboard from "clipboard-polyfill";

import { constantStyle } from "../../util/constant";

export default function Brandpostingdetails() {
  const { state } = useLocation();
  const data = state.cardDetail;
  let { id } = useParams();
  const [open, setOpen] = useState(false);
  const { collabUsers, getCollabUsers } = useContext(BrandPostingContext);
  const { setShow, setMessage } = useContext(AlertMessageContext);
  const handleClose = () => {
    setOpen(false);
  };

  const navigator = useNavigate();

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  //  commented copy  code is deplicated code but code is working fine

  // const copyLinkToClipboard = (link) => {

  //   try {

  //     const tempInput = document.createElement("textarea");

  //     tempInput.value = link;

  //     document.body.appendChild(tempInput);

  //     tempInput.select();

  //     document.execCommand("copy");

  //     document.body.removeChild(tempInput);

  //     setShow(true);

  //     setMessage({ text: "Link Copied", type: "success" });

  //   }

  //   catch (err) {

  //     setShow(true);

  //     setMessage({ text: "Link no Copied", type: "error" });

  //   }

  // };

  //post link copy to clipboard

  // for working code install library clipboard-polyfill library

  const copyLinkToClipboard = async (link) => {
    try {
      await clipboard.writeText(link);
      setShow(true);
      setMessage({ text: "Link Copied", type: "success" });
    } catch (err) {
      setShow(true);
      setMessage({ text: "Failed to link copy ", type: "error" });
    }
  };

  // modify collab user post link

  const modifydata = collabUsers.map((user) => {
    const LinkIcon = user.link.includes("https://youtu.be") ? (
      <YouTube style={constantStyle.youtubeIcon} />
    ) : (
      <Instagram />
    );

    return {
      ...user,

      link: (
        <div onClick={() => copyLinkToClipboard(user.link)}>{LinkIcon}</div>
      ),
    };
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [rows, setRows] = useState();
  const handleClick = (data) => {
    setRows(data);
    setOpen(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);

    setPage(0);
  };

  const getRowCell = (column, value, row) => {
    switch (column.id) {
      case "photo":
        return (
          <TableCell key={column.id} align={column.align}>
            <CardHeader
              sx={{ padding: 0, marginRight: "0" }}
              title={row.fullName}
              avatar={
                <Avatar
                  sx={{
                    height: 17,

                    width: 17,

                    marginRight: "0",
                  }}
                  alt={row.fullName}
                  src={row.photo}
                />
              }
            />
          </TableCell>
        );

      case "icon":
        return (
          <TableCell key={column.id} align={column.align}>
            <RemoveRedEyeIcon
              sx={{ color: colors.primaryColor, cursor: "pointer" }}
              onClick={() => handleClick(row)}
            />
          </TableCell>
        );

      default:
        return (
          <TableCell key={column.id} align={column.align}>
            {column.format && typeof value === "number"
              ? column.format(value)
              : value}
          </TableCell>
        );
    }
  };

  useEffect(() => {
    getCollabUsers(data);
  }, [data, getCollabUsers]);

  return (
    <Box className="brandpost-details">
      <Button
        style={brandStyle.backButton}
        variant="contained"
        onClick={() => navigator("/brand-posting")}
      >
        <ArrowBack style={brandStyle.arrowBack} /> Back
      </Button>

      <Carddetails data={data} id={id} />

      {data.publish === true ? (
        <Box>
          <Paper sx={brandStyle.brandPostDetailPaper}>
            <TableContainer sx={{ overflowY: "auto" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                {modifydata.length > 0 ? (
                  <TableBody>
                    {modifydata

                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )

                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];

                              const cellData = getRowCell(column, value, row);

                              return cellData;
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableCell>No found data</TableCell>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>

          {modifydata.length > 10 && (
            <TablePagination
              rowsPerPageOptions={[]}
              component="article"
              count={modifydata.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                native: false,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Box>
      ) : null}

      <BrandpostingDetailsModal
        open={open}
        actions={handleClose}
        data={rows}
        collabUsers={collabUsers}
      />
    </Box>
  );
}

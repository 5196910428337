import { Search } from "@mui/icons-material"
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material"
import React, { useState } from "react"
import CreateBrandPostingModal from "../../pages/brand-posting/CreateBrandPosting"
import Addplus from "../Public-icons/addplus"

export default function Searchbar({ searchText, setSearchText, handleSearch, searchQuery }) {
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div className="searchbar">
      <TextField
        name="search"
        value={searchText || searchQuery}
        onChange={(e) => (handleSearch ? handleSearch(e.target.value) : setSearchText(e.target.value))}
        variant="outlined"
        placeholder="Search…"
        color="primary"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box className="rightbtn">
        <Button
          variant="contained"
          onClick={handleOpen}
          sx={{ letterSpacing: "0.3em" }}
        >
          <Addplus /> Create
        </Button>
      </Box>
      {open && (
        <CreateBrandPostingModal
          open={open}
          actions={handleClose}
        />
      )}
    </div>
  )
}

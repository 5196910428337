import React, { useContext, useEffect, useState } from "react";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import { BrandPostingContext } from "../../context/BrandPostProvider";
import { brandStyle } from "../../util/brandStyle";

export default function Carddetails({ data, id }) {
  const { brandData } = useContext(BrandPostingContext);
  const [cardData, setCardData] = useState("");

  useEffect(() => {
    if (data) {
      setCardData(data);
    } else {
      const card = brandData.find((cardData) => cardData.id === data.id);
      setCardData(card);
    }
  }, [data, brandData, id]);

  return (
    <Box className="brandposting-crds">
      <Card className="postcards">
        <Box className="postdetails">
          <Box sx={brandStyle.cardDetailsMainBox}>
            <Box sx={brandStyle.Boxsiz}>
              <CardContent>
                <Typography variant="body1">Name</Typography>
                {cardData && (
                  <Typography variant="subtitle2">
                    {cardData.brandName}
                  </Typography>
                )}
              </CardContent>
              <CardContent>
                <Typography variant="body1">Platform</Typography>
                {cardData?.platform?.map((platform, index) => (
                  <Typography variant="subtitle2" key={index}>
                    {platform?.text}
                  </Typography>
                ))}
              </CardContent>
            </Box>

            <Box sx={brandStyle.Boxsiz}>
              <CardContent>
                <Typography variant="body1">Genre</Typography>
                <Box sx={brandStyle.categoryBox}>
                  {cardData?.brandCategory?.map((category, index) => (
                    <Typography variant="subtitle2" key={index}>
                      {category.text +
                        (index !== cardData?.brandCategory?.length - 1
                          ? ", "
                          : "")}
                    </Typography>
                  ))}
                </Box>
              </CardContent>
              <CardContent>
                <Typography variant="body1">Deliverables</Typography>
                <Box sx={brandStyle.categoryBox}>
                  {cardData?.deliverables?.map((delivery, index) => (
                    <Typography variant="subtitle2" key={index}>
                      {delivery.text +
                        (index !== cardData?.deliverables?.length - 1
                          ? ", "
                          : "")}
                    </Typography>
                  ))}
                </Box>
              </CardContent>
            </Box>

            <Box
              sx={brandStyle.Boxsiz}
              style={{ borderRight: "none", padding: 0 }}
            >
              <CardContent>
                <Typography variant="body1">Requirements</Typography>

                <Typography variant="subtitle2">
                  {cardData?.requiredFollower?.text}
                </Typography>
              </CardContent>
              <CardContent>
                <Typography variant="body1">What Do You Get</Typography>
                <Typography variant="subtitle2">
                  {cardData?.what_do_you_get}
                </Typography>
              </CardContent>
            </Box>

            <CardContent sx={{ pt: "10px!important" }}>
              <Typography variant="body1">Description:</Typography>
              <Typography variant="subtitle2">{cardData?.about}</Typography>
            </CardContent>
            <CardContent>
              <Typography variant="body1">PS</Typography>
              <Typography variant="subtitle2">{cardData?.PS}</Typography>
            </CardContent>
          </Box>
          <CardMedia
            component="img"
            image={cardData?.brandLogo}
            alt="brandLogo"
          />
        </Box>
      </Card>
    </Box>
  );
}

import React from "react"

export default function TransactionIcon() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.1" cx="25" cy="25" r="25" fill="#E2374B" />
      <g clipPath="url(#clip0_470_403)">
        <path
          d="M17.6833 27.3684H15.8763C15.6131 27.3684 15.3982 27.5833 15.3982 27.8465V34.5219C15.3982 34.7851 15.6131 35 15.8763 35H17.6833C17.9464 35 18.1614 34.7851 18.1614 34.5219V27.8465C18.1614 27.5833 17.9464 27.3684 17.6833 27.3684Z"
          fill="#E2374B"
        />
        <path
          d="M34.4377 27.9123C34.4245 27.8947 34.4114 27.8772 34.4026 27.8596C34.1351 27.5614 33.6833 27.5219 33.3675 27.7675C32.4903 28.4825 31.2096 29.5132 30.3807 30.1842C29.6482 30.7675 28.7447 31.1053 27.8061 31.1403L25.5605 31.2149C25.1482 31.2281 24.7623 31.0175 24.5517 30.6623L24.3017 30.2368C24.2359 30.1272 24.1921 30.0044 24.1658 29.8772C24.0517 29.2675 24.4509 28.6754 25.0605 28.5614L27.3807 28.1228C27.7535 28.0482 28.0079 27.6974 27.964 27.3202C27.9202 26.9605 27.6131 26.693 27.2535 26.6886C27.2403 26.6886 27.2228 26.6886 27.2184 26.6886C27.2009 26.6886 27.1789 26.6886 27.1614 26.6886L24.0386 26.5614C22.9552 26.5219 22.021 26.7325 20.9158 27.2675L19.0386 28.1754V33.1754L20.6131 32.886C20.6175 32.8816 20.6219 32.8816 20.6263 32.8816C21.2359 32.7939 21.6395 32.7544 22.3017 32.7851L27.0166 33.0044C28.4947 33.0658 29.9465 32.5789 31.0824 31.6316L34.3456 28.9254C34.6482 28.6711 34.6877 28.2149 34.4377 27.9123Z"
          fill="#E2374B"
        />
        <path
          d="M28.4815 15C25.7973 15.0044 23.6262 17.1798 23.6218 19.8596C23.6218 19.864 23.6218 19.864 23.6218 19.864C23.6218 22.5482 25.8017 24.7237 28.4859 24.7237C31.1701 24.7237 33.3455 22.5439 33.3455 19.8596C33.3455 17.1754 31.1657 15 28.4815 15ZM29.6087 18.5088H30.1964C30.4376 18.5088 30.635 18.7061 30.635 18.9474C30.635 19.1886 30.4376 19.386 30.1964 19.386H29.6087C29.5209 19.7281 29.3455 20.0395 29.0955 20.2895C28.7666 20.6184 28.328 20.8114 27.8631 20.8377L29.5166 22.4035C29.692 22.5702 29.7008 22.8465 29.5341 23.0219C29.3674 23.1974 29.0911 23.2061 28.9157 23.0395L26.4639 20.7193C26.3806 20.636 26.328 20.5219 26.328 20.4035C26.3236 20.1579 26.5209 19.9605 26.7666 19.9561H27.749C28.0209 19.9605 28.2841 19.8553 28.4815 19.6623C28.5604 19.5789 28.6262 19.4868 28.6832 19.386H26.7666C26.5253 19.386 26.328 19.1886 26.328 18.9474C26.328 18.7061 26.5253 18.5088 26.7666 18.5088H28.6832C28.5209 18.1579 28.1613 17.8947 27.749 17.8947H26.7666C26.5253 17.8947 26.328 17.6974 26.328 17.4561C26.328 17.2149 26.5253 17.0175 26.7666 17.0175H30.1964C30.4376 17.0175 30.635 17.2149 30.635 17.4561C30.635 17.6974 30.4376 17.8947 30.1964 17.8947H29.3543C29.4771 18.0833 29.5604 18.2895 29.6087 18.5088Z"
          fill="#E2374B"
        />
      </g>
      <defs>
        <clipPath id="clip0_470_403">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(15 15)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

import { FileCopy } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, DialogContent, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import React, { Fragment } from "react";
import { constantStyle } from "../../util/constant";
import { Carousel } from "react-responsive-carousel";
import "./style.css";
export default function Dispute_Modal({ open, actions }) {
  const data = open;
  const copyToClipboard = () => {
    const textToCopy = data.userId;
    navigator.clipboard.writeText(textToCopy);
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        maxWidth="md"
        sx={{
          width: "43%",
          margin: "auto",
          "& .MuiPaper-root": { width: "500px" },
        }}
        className="detailsmodal-main"
      >
        <DialogTitle sx={constantStyle.dialogTitle} variant="h3">
          Dispute Details
          <DialogActions onClick={actions} sx={constantStyle.dialogAction}>
            <CloseIcon sx={constantStyle.closeIcon} />
          </DialogActions>
        </DialogTitle>

        <DialogContent sx={constantStyle.dialogContent}>
          <Box
            sx={{
              // mt: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "auto",
            }}
          >
            {/* Will be use in future  */}

            {/* <Typography variant="h6" className="gap5">
              Images
            </Typography>
            {data.images && data.images.length > 0 ? (
              <Carousel showStatus={false} showThumbs={false}>
                {data.images.map((imageUrl, index) => (
                  <div key={index}>
                    <img
                      src={imageUrl}
                      alt={`Image ${index + 1}`}
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                ))}
              </Carousel>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2">No images found.</Typography>
            </Box>
            )} */}
            {/* <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2">{data.images}</Typography>
            </Box> */}
          </Box>
          <Box sx={{ mt: "0px" }} className="d-flex boxinfo">
            <Box sx={{ width: "100%", marginRight: "30px" }}>
              <Typography variant="h6" className="gap5">
                Title
              </Typography>
              <Typography variant="body2" sx={{ marginTop: "5px" }}>
                {data.title}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" className="gap5">
                Date & Time of Dispute
              </Typography>
              <Typography variant="body2">{data.createdAt}</Typography>
            </Box>
          </Box>

          <Box sx={{ mt: "15px" }} className="d-flex boxinfo">
            <Box sx={{ marginRight: "30px" }}>
              <Typography variant="h6" className="gap5">
                Post Id
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body2">{data.postId}</Typography>
                <IconButton onClick={copyToClipboard} sx={{ p: 0, pl: "5px" }}>
                  <FileCopy sx={{ height: "15px", width: "15px" }} />
                </IconButton>
              </Box>
            </Box>

            <Box sx={constantStyle.dialogInnerBox}>
              <Typography variant="h6" className="gap5">
                Amount
              </Typography>
              <Typography variant="body2" sx={{ marginTop: "5px" }}>
                {data.amount}
              </Typography>
            </Box>
          </Box>
          <Box className="d-flex boxinfo">
            <Box sx={constantStyle.dialogInnerBox}>
              <Typography variant="h6" className="gap5">
                Description
              </Typography>
              <Typography
                variant="body2"
                sx={{ marginTop: "5px", width: "90%" }}
              >
                {data.description}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

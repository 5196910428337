import { collection, getDocs } from "firebase/firestore";
import React, { createContext, useContext, useEffect, useState } from "react";
import { fireStore } from "../firebase";
import { AlertMessageContext } from "./AlertMessageProvider";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [usersData, setUsersData] = useState([]);
  const { setShow, setMessage } = useContext(AlertMessageContext);
  const getUsersData = async () => {
    try {
      const docRef = collection(fireStore, "USERS");
      const response = await getDocs(docRef);
      const data = response?.docs?.map((doc) => {
      const formattedDate = new Date(doc.data().createdAt.toDate()).toLocaleString();
        return {
          personName: doc.data().displayName,
          phone: doc.data().phone,
          email: doc.data().email,
          gender: doc.data().gender,
          customerId: doc.data().id.substring(0, 8) + "...",
          createdAt: formattedDate,
          id: doc.id,
        };
      }) ?? [];
      setUsersData(data);
    } catch (err) {
      setShow(true);
      setMessage({ text: "No data found", type: "error" });
    }
  };
  useEffect(() => {
    getUsersData();
  }, []);

  return (
    <UserContext.Provider value={{ usersData }}>
      {children}
    </UserContext.Provider>
  );
};

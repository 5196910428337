export const colors = {
  primaryColor: "#E2374B",
  secondaryColor: "#7F7F7F",
  black: "#262626",
  white: "#FFFFFF",
  light_red: "rgba(226, 55, 75, 0.3)",
  grey: "#C5C5C5",
  light_grey: "#E0E0E0",
  very_light_grey: "#EFEFEF",
  extra_light_grey: "#9E9E9E",
  primary_with_opacity_5: "rgba(226,55,75,0.05)",
  primary_with_opacity_80: "rgba(226,55,75,0.8)",
  line_color: "#A6A6A6",
  green: "#4CAF50",
  grey_300: "#B0BEC5",
  grey_800: "#F3F3FF",
  red: "#FF0000",
  grey_200: "#BDBDBD",
  white_opacity_80: "#F4B7BE",
  body_bg: "#fafafa",
  tabHeadTitle: "#7C7F84",
  tabHead: "F3F3FF",
}

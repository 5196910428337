export const dileverableData = [
    {
        id: 1,
        text: "Reel",
    },
    {
        id: 2,
        text: "Video",
    },
    {
        id: 3,
        text: "Post",
    },
    {
        id: 4,
        text: "Story",

    },

]
export const withdrawalrequestcolumns = [
    { id: "id", label: "Sr. No.", minWidth: 116 },
    { id: "accountHolder", label: "Person name", minWidth: 178 },
    {
        id: "accountNumber",
        label: "Account no.",
        minWidth: 170,
        align: "left",
    },
    {
        id: "bankName",
        label: "Bank Name",
        minWidth: 170,
        align: "left",
    },
    {
        id: "ifscCode",
        label: "ifsc",
        minWidth: 170,
        align: "left",
    },
    {
        id: "amount",
        label: "amount",
        minWidth: 170,
        align: "left",
    },
    {
        id: "createdAt",
        label: "Date",
        minWidth: 80,
        align: "left",
    },

]
export const collabrationscolumns = [
    { id: "personName", label: "Creator Name" },
    {
        id: "collaboratorName",
        label: "Collaborator Name",
        align: "left",
    },
    {
        id: "type",
        label: "Type",
        align: "left",
    },
    {
        id: "dateTime",
        label: "Date Time",
        align: "left",
    },
    {
        id: "amount",
        label: "Amount",
        align: "left",
    },
    {
        id: "icon",
        minWidth: 20,
        align: "center",
    },
]

export const servicefeecolumns = [
    { id: "creatorName", label: "Creator Name" },
    {
        id: "collaboratorName",
        label: "Collaborator Name",
        align: "left",
    },
    {
        id: "type",
        label: "Type",
        align: "left",
    },
    {
        id: "createdAt",
        label: "Date Time",
        align: "left",
    },
    {
        id: "amount",
        label: "Amount",
        align: "left",
    },
    {
        id: "receiverAmount",
        label: "Service Fee",
        align: "left",
    },
    {
        id: "icon",
        minWidth: 20,
        align: "center",
    },
]


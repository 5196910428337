import { ThemeProvider } from "@mui/material/styles";
import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import AlertMessage from "./components/Alert/AlertMessage";
import PageLayout from "./components/pagelayout/PageLayout";
import AlertMessageProvider from "./context/AlertMessageProvider";
import BrandPostProvider from "./context/BrandPostProvider";
import { auth } from "./firebase";
// import ForgotPassword from "./pages/forgot-password"
import Login from "./pages/login";
// import ResetPassword from "./pages/reset-password"
import Splash from "./Splash";
import { theme } from "./theme";

function App() {
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    onAuthStateChanged(auth, (userData) => {
      if (userData) {
        setUser(userData);
      } else {
        setUser(null);
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  }, []);
  if (loading) {
    return (
      <div>
        <Splash />
      </div>
    );
  }
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AlertMessageProvider>
          <AlertMessage />
          {!user ? (
            <Routes>
              <Route path="/" element={<Login />} />
              {/* Will be added later */}
              {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
              {/* <Route path="/reset-password" element={<ResetPassword />} /> */}
            </Routes>
          ) : (
            <PageLayout />
          )}
        </AlertMessageProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

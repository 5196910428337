import React from "react"

export default function Copy() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_324_2823)">
        <path
          d="M5.27282 10.5626C4.00368 10.5626 2.9707 9.52965 2.9707 8.26051V2.7085H2.02282C1.2011 2.7085 0.533203 3.37629 0.533203 4.19801V11.5105C0.533203 12.3322 1.2011 13.0001 2.02282 13.0001H8.79359C9.61531 13.0001 10.2832 12.3322 10.2832 11.5105V10.5626H5.27282Z"
          fill="#C0C0C0"
        />
        <path
          d="M12.4498 1.48962C12.4498 0.666801 11.783 0 10.9603 0H5.27282C4.45 0 3.7832 0.666801 3.7832 1.48962V8.26038C3.7832 9.0832 4.45 9.75 5.27282 9.75H10.9603C11.783 9.75 12.4498 9.0832 12.4498 8.26038V1.48962Z"
          fill="#C0C0C0"
        />
      </g>
      <defs>
        <clipPath id="clip0_324_2823">
          <rect width="13" height="13" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

import CloseIcon from "@mui/icons-material/Close"
import {
  Box,
  Button, DialogContent, Tooltip, Typography
} from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import * as clipboard from "clipboard-polyfill"
import React, { Fragment, useContext, useState } from "react"
import { AlertMessageContext } from "../../context/AlertMessageProvider"
import { constantStyle } from "../../util/constant"
import Copy from "../Public-icons/copy"

export default function BrandpostingDetailsModal({ open, actions, data, collabUsers }) {

  const { setShow, setMessage } = useContext(AlertMessageContext)
  const [toolopen, setToolopen] = useState(false)

  //post link copy to clipboard  and settoolopen is to show copy post link 
  const handleTooltipOpen = async (link) => {
    setToolopen(true)
    try {
      await clipboard.writeText(link);
      setTimeout(() => {
        setToolopen(false)
      }, 500)
    } catch (err) {
      setShow(true);
      setMessage({ text: "Failed to link copy ", type: "error" });
    }
  }

  const viewData = collabUsers.find(obj => obj.postId === (data ? data.postId : 0));

  return (
    <Fragment>
      <Dialog open={open} maxWidth="md" className="detailsmodal-main">
        <DialogTitle
          sx={constantStyle.dialogTitle}
          variant="h3"
        >
          Details
          <DialogActions
            onClick={actions}
            sx={constantStyle.dialogAction}
          >
            <CloseIcon sx={constantStyle.closeIcon} />
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ p: "20px" }}>
          <Box className="Detailsmodal">
            <Box>
              <Typography variant="h6" className="gap5">
                Name
              </Typography>
              <Typography variant="body2">{viewData?.fullName}</Typography>
            </Box>
            <Box>
              <Typography variant="h6" className="gap5">
                Gender
              </Typography>
              <Typography variant="body2">{viewData?.gender}</Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant="h6" className="gap5">
              Post Link
            </Typography>

            <Typography variant="body2" className="d-flex align-items-center">
              {viewData?.link}
              <Tooltip
                placement="top"
                PopperProps={{
                  disablePortal: true,
                }}
                open={toolopen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copied"
              >
                <Button
                  onClick={() => handleTooltipOpen(viewData?.link)}
                  sx={{
                    p: 0,
                    marginLeft: "5px",
                    background: "none",
                    minWidth: "initial",
                    "&:hover": {
                      background: "none",
                    },
                  }}
                >
                  <Copy />
                </Button>
              </Tooltip>
            </Typography>

          </Box>

          <Box className="d-flex justify-content-between boxinfo">
            <Box>
              <Typography variant="h6" className="gap5">
                Email ID
              </Typography>
              <Typography variant="body2">{viewData?.email}</Typography>
            </Box>
            <Box>
              <Typography variant="h6" className="gap5">
                Whatsapp Number
              </Typography>
              <Typography variant="body2">{viewData?.whatsapp_number}</Typography>
            </Box>
            <Box>
              <Typography variant="h6" className="gap5">
                Calling Number
              </Typography>
              <Typography variant="body2">{viewData?.calling_number}</Typography>
            </Box>
          </Box>

          <Box sx={{ mt: "15px" }}>
            <Typography variant="h6" className="gap5">
              Address
            </Typography>
            <Typography variant="body2">
              {viewData?.address}
            </Typography>
          </Box>

          <Box className="d-flex justify-content-between boxinfo">
            <Box>
              <Typography variant="h6" className="gap5">
                Landmark
              </Typography>
              <Typography variant="body2">{viewData?.landmark}</Typography>
            </Box>
            <Box>
              <Typography variant="h6" className="gap5">
                City
              </Typography>
              <Typography variant="body2">{viewData?.city}</Typography>
            </Box>
            <Box>
              <Typography variant="h6" className="gap5">
                State
              </Typography>
              <Typography variant="body2">{viewData?.state}</Typography>
            </Box>
            <Box>
              <Typography variant="h6" className="gap5">
                Pincode
              </Typography>
              <Typography variant="body2">{viewData?.pinCode}</Typography>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { DisputesProvider } from "../../context/DisputesProvider";
import FaqProvider from "../../context/FaqProvider";
import { constantStyle } from "../../util/constant";
import { customerStyle } from "../../util/cutomerStyle";
import { Disputes } from "./disputes";
import FaqAccordions from "./faqaccordions";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function CustomerSupport() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <FaqProvider>
      <DisputesProvider>
          <Box className="transaction-tab">
            <Box sx={customerStyle.tabPanel}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="FAQs"
                  {...a11yProps(0)}
                  sx={constantStyle.tabLabel}
                />
                <Tab
                  label="Disputes"
                  {...a11yProps(1)}
                  sx={{ ml: "30px", p: 0 }}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <FaqAccordions />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Disputes />
            </TabPanel>
          </Box>
      </DisputesProvider>
    </FaqProvider>
  );
}

import React, { useContext, useEffect, useState } from "react"
import AddIcon from "@mui/icons-material/Add"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import RemoveIcon from "@mui/icons-material/Remove"
import { Box, Button } from "@mui/material"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import { colors } from "../../util"
import AccordionsCreatemodal from "./accordions-createmodal"
import { FaqContext } from "../../context/FaqProvider"
import { Loader } from "../../components/Loader"

export default function FaqAccordions() {
  const [open, setOpen] = useState(false);
  const { faqData, loader } = useContext(FaqContext);
  const handleClose = () => {
    setOpen(false);
  };

  const [expanded, setExpanded] = useState("");

  useEffect(() => {
    if (faqData.length > 0) {
      setExpanded(faqData[0].id);
    }
  }, [faqData]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };




  return (
    <Box className="question-answer">
      {loader ? <Loader /> : <Box className="rightbtn">
        <Button
          onClick={() => setOpen(true)}
          variant="contained"
          sx={{ letterSpacing: "0.3em" }}
        >
          <AddCircleIcon /> Create
        </Button>
      </Box>
      }

      {faqData.map((data, index) => {
        const { id, ques, answer } = data;
        return (
          <Accordion
            key={index}
            expanded={expanded === id}
            onChange={handleChange(id)}
          >
            <AccordionSummary
              expandIcon={
                expanded === id ? (
                  <RemoveIcon sx={{ color: colors.primaryColor }} />
                ) : (
                  <AddIcon sx={{ color: colors.primaryColor }} />
                )
              }
              aria-controls="panel1bh-content"
            >
              <Typography>{ques}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{answer}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
      {open && <AccordionsCreatemodal open={open} action={handleClose} />}
    </Box>
  );
}


import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined"
import { IconButton, Paper, Typography } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import { signOut } from "firebase/auth"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { auth } from "../../firebase"
import { colors } from "../../util"

export default function AlertDialog() {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const navigate = useNavigate()

  const onClickLogout = () => {
    signOut(auth)
      .then(() => {
        navigate("/")
        window.history.pushState(null, window.location.href)
        window.addEventListener("popstate", function (event) {
          window.history.pushState(null, window.location.href)
        })
      })
      .catch((error) => {
        alert(error)
      })
  }

  return (
    <div>
      <Paper elevation={0} className="toolbarmain">
        <IconButton onClick={handleClickOpen} disableRipple>
          <LogoutOutlinedIcon />
        </IconButton>
      </Paper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h2" sx={{ color: colors.black }}>
            Are You sure you want to logout ?
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{
              background: colors.grey,
              color: colors.white,
              padding: "5px 20px",
            }}
          >
            No
          </Button>
          <Button
            onClick={onClickLogout}
            style={{
              background: colors.primaryColor,
              color: colors.white,
              padding: "5px 20px",
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

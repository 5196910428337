import React from "react"

export default function Excel() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_313_86)">
        <path
          d="M1.3776 1.39163L11.1717 -0.0069772C11.227 -0.0149091 11.2834 -0.0108574 11.3369 0.00488956C11.3905 0.0206365 11.4401 0.0477125 11.4822 0.0842866C11.5244 0.120869 11.5583 0.166084 11.5815 0.216885C11.6046 0.267679 11.6166 0.322867 11.6166 0.37871V16.6212C11.6166 16.677 11.6046 16.7321 11.5815 16.7828C11.5583 16.8336 11.5246 16.8788 11.4825 16.9153C11.4404 16.9518 11.3909 16.979 11.3374 16.9948C11.284 17.0106 11.2277 17.0147 11.1725 17.0069L1.37682 15.6083C1.19109 15.5818 1.02113 15.4893 0.898174 15.3475C0.775221 15.2059 0.707527 15.0246 0.70752 14.837V2.163C0.707527 1.97539 0.775221 1.79407 0.898174 1.65237C1.02113 1.51067 1.19187 1.41808 1.3776 1.39163ZM12.3958 1.48746H15.5125C15.7191 1.48746 15.9173 1.56956 16.0634 1.71567C16.2096 1.8618 16.2916 2.05998 16.2916 2.26663V14.7333C16.2916 14.94 16.2096 15.1381 16.0634 15.2843C15.9173 15.4304 15.7191 15.5125 15.5125 15.5125H12.3958V1.48746ZM7.09745 8.49999L9.27912 5.3833H7.40912L6.16247 7.1645L4.9158 5.3833H3.0458L5.22747 8.49999L3.0458 11.6167H4.9158L6.16247 9.83548L7.40912 11.6167H9.27912L7.09745 8.49999Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_313_86">
          <rect width="17" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

import {
  collection,
  collectionGroup,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { createContext, useEffect, useState } from "react";
import { fireStore } from "../firebase";

export const DisputesContext = createContext();

export const DisputesProvider = ({ children }) => {
  const [disputeData, setDisputeData] = useState();
  const getDisputeRequests = async () => {
    const disputeRequestRef = collection(fireStore, "DISPUTE_REQUEST");
    const response = await getDocs(disputeRequestRef);
    const data = response?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));

    const allSubCollectionData = [];

    await Promise.all(
      data.map(async (item) => {
        const disputeCreatorRef = collection(
          fireStore,
          "DISPUTE_REQUEST/" + item.id + "/DISPUTE_CREATOR"
        );
        const subCollectionResponse = await getDocs(disputeCreatorRef);
        const subCollectionData = subCollectionResponse.docs.map((doc) =>
          doc.data()
        );
        allSubCollectionData.push(subCollectionData);
      })
    );

    const usersRef = collection(fireStore, "USERS");
    const usersResponse = await getDocs(usersRef);
    const usersData = usersResponse.docs.map((doc) => doc.data());

    const formattedData = allSubCollectionData.map((subCollectionData) =>
    subCollectionData.map((item) => {
      const user = usersData.find((user) => user.id === item.disputeCreatorId);
      const collabUser = usersData.find((user) => user.id === item.disputeCollaboratorId);
      return {
        ...item,
        createdAt: new Date(item.createdAt.toDate()).toLocaleString(),
        personName: user ? user.displayName : null,
        againstWhom: collabUser ? collabUser.displayName : null,
      };
    })
  );
    setDisputeData(formattedData);
  };

  useEffect(() => {
    getDisputeRequests();
  }, []);

  return (
    <DisputesContext.Provider value={{ disputeData }}>
      {children}
    </DisputesContext.Provider>
  );
};

import { FileCopy } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, DialogContent, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import React, { Fragment } from "react";
import { constantStyle } from "../../util/constant";

export default function GroomYourContent_Modal({ open, actions }) {
  const rowData = open;
  const copyToClipboard = () => {
    const textToCopy = rowData.userId;
    navigator.clipboard.writeText(textToCopy)
  };

  
  const renderAdditionalContent = () => {
    switch (rowData.typeOfProfessional) {
      case "Influencer Photography":
        return (
          <Fragment>
            <Box sx={{ mt: "15px" }}>
              <Typography variant="h6" className="gap5">
                Location
              </Typography>
              <Typography variant="body2">{rowData.location}</Typography>
            </Box>
          </Fragment>
        );

      case "Photo Editor":
        return (
          <Fragment>
            <Box sx={{ mt: "15px" }}>
              <Typography variant="h6" className="gap5">
                Photo Editor
              </Typography>
              <Typography variant="body2">{rowData.photoEditor}</Typography>
            </Box>
          </Fragment>
        );

      
      default:
        // For any other case (including "Other"), show the description
        return (
          <Fragment>
            <Box sx={{ mt: "15px" }}>
              <Typography variant="h6" className="gap5">
                Description
              </Typography>
              <Typography variant="body2">{rowData.description}</Typography>
            </Box>
          </Fragment>
        );
    }
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        maxWidth="md"
        sx={{ width: "43%", margin: "auto" }}
        
        className="detailsmodal-main"
      >
        <DialogTitle sx={constantStyle.dialogTitle} variant="h3">
          Request Details
          <DialogActions onClick={actions} sx={constantStyle.dialogAction}>
            <CloseIcon sx={constantStyle.closeIcon} />
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={constantStyle.dialogContent}>
          <Box sx={{ mt: "15px" }}>
            <Typography variant="h6" className="gap5">
              Customer Id
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2">{rowData.userId}</Typography>
            <IconButton onClick={copyToClipboard} size="small">
              <FileCopy />
            </IconButton>
            </Box>
          </Box>
          <Box className="d-flex boxinfo">
            <Box sx={constantStyle.dialogInnerBox}>
              <Typography variant="h6" className="gap5">
                Date & Time of Request
              </Typography>
              <Typography variant="body2">{rowData.createdAt}</Typography>
            </Box>
          </Box>
          {renderAdditionalContent(rowData)}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

export const columns = [
    { id: "photo", label: "name", },
    {
        id: "gender",
        label: "Gender",
        align: "left",
    },
    {
        id: "link",
        label: "post link",
        align: "left",
    },
    {
        id: "whatsapp_number",
        label: "whatsapp no.",
        align: "left",
    },
    {
        id: "calling_number",
        label: "calling no.",
        align: "left",
    },
    {
        id: "city",
        label: "city",
        align: "left",
    },

    {
        id: "state",
        label: "state",
        align: "left",
    },
    {
        id: "icon",
        align: "center",
    },
]
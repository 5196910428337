import {
    collection, doc,
    getDocs, setDoc
} from "firebase/firestore";
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { fireStore } from "../firebase";
import { AlertMessageContext } from "./AlertMessageProvider";

export const FaqContext = createContext();

function FaqProvider({ children }) {
    const [faqData, setFaqData] = useState([])
    const { setShow, setMessage } = useContext(AlertMessageContext)
    const [loader, setLoader] = useState(false)

    // add Faq data to firestore & data from accordion-createmodal file 
    const addFaqData = async (data) => {

        try {
            setLoader(true)
            const docRef = doc(collection(fireStore, "FAQ"));
            await setDoc(docRef, data);
            getFaqData();
        } catch (err) {
            setShow(true);
            setMessage({ text: "No Data Added", type: "error" });
        } finally {
            setLoader(false)
        }
    }

    // reterive Faq data from firestore
    const getFaqData = useCallback(async () => {
        try {
            setLoader(true)
            const docRef = collection(fireStore, "FAQ");
            const data = await getDocs(docRef);
            setFaqData(data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id })) ?? []);
        } catch (err) {
            setShow(true);
            setMessage({ text: "No data found", type: "error" });
        } finally {
            setLoader(false)
        }
    }, [setShow, setMessage])

    useEffect(() => {
        getFaqData();
    }, [getFaqData])

    return (
        <FaqContext.Provider value={{ addFaqData, getFaqData, faqData, loader }}>
            {children}
        </FaqContext.Provider>
    )
}

export default FaqProvider
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "@firebase/firestore";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { fireStore } from "../firebase";
import { AlertMessageContext } from "./AlertMessageProvider";

export const PostContext = createContext();

export const PostProvider = ({ children }) => {
  const [post, setPosts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filterPostData, setFilterPostData] = useState("");
  const { setShow, setMessage, message } = useContext(AlertMessageContext);

  const getPostData = async () => {
    try {
      const postRef = collection(fireStore, "POSTS");
      const response = await getDocs(postRef);
      const data = response?.docs?.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        createdAt: new Date(doc.data().createdAt.toDate()).toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        }),
      }));
      setPosts(data);
      setFilterPostData(data);
    } catch (err) {
      setShow(true);
      setMessage({ text: "No data found", type: "error" });
    }
  };

  const filterData = (data, searchText) => {
    const lowercaseSearchText = searchText.toLowerCase();
    return data.filter((item) => {
      return (
        (item.type ?? "").toLowerCase().includes(lowercaseSearchText) ||
        (item.topic ?? "").toLowerCase().includes(lowercaseSearchText) ||
        (item.platform ?? "").toLowerCase().includes(lowercaseSearchText) ||
        (item.location ?? "").toLowerCase().includes(lowercaseSearchText)
      );
    });
  };

  const getFilterPostData = useCallback(() => {
    if (searchText === "") {
      setPosts(filterPostData);
    } else {
      const filteredData = filterData(filterPostData, searchText);
      setPosts(filteredData);
    }
  }, [filterPostData, searchText]);

  useEffect(() => {
    getFilterPostData();
  }, [searchText, getFilterPostData]);

  useEffect(() => {
    getPostData();
  }, []);

  return (
    <PostContext.Provider
      value={{ post, message, getFilterPostData, searchText, setSearchText }}
    >
      {children}
    </PostContext.Provider>
  );
};

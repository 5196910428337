import { Box, Tab, Tabs, Typography } from "@mui/material"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import Searchbar from "../../components/BrandpostingData/searchbar"
import Marketing from "../../components/Public-icons/marketing"
import { BrandPostingContext } from "../../context/BrandPostProvider"
import { colors } from "../../util"
import { brandStyle } from "../../util/brandStyle"
import { constantStyle } from "../../util/constant"
import BrandCardData from "./brandDataCards"
import SaveDraft from "./SaveDraft"
import "./style.css"
function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  )
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export default function BrandPosting() {
  const { brandData, brandDraftData, tabValue, setTabValue, setSearchText, searchText } = useContext(BrandPostingContext)
  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <Box>
      <Searchbar setSearchText={setSearchText} searchText={searchText} />
      <Box
        sx={brandStyle.brandPostingMain}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={`Published (${brandData.length})`}
            {...a11yProps(0)}
            sx={constantStyle.tabLabel}
          />
          <Tab
            label={`Draft (${brandDraftData.length})`}
            {...a11yProps(1)}
            sx={{ ml: 4, pt: 0, pb: 0 }}
          />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        {brandData.length > 0 ? (
          <BrandCardData />
        ) : (
          <Box className="emptyscreen">
            <Marketing />
            <Typography variant="h3" sx={{ color: colors.secondaryColor }}>
              There is nothing to display
            </Typography>
            <Typography variant="h5">Create your first Brand Post</Typography>
          </Box>
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {brandDraftData.length > 0 ? (
          <SaveDraft />
        ) : (
          <Box className="emptyscreen">
            <Marketing />
            <Typography variant="h3" sx={{ color: colors.secondaryColor }}>
              There is nothing to display
            </Typography>
            <Typography variant="h5">Create your first Brand Post</Typography>
          </Box>
        )}
      </TabPanel>
    </Box>
  )
}

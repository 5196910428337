import React from "react"

export default function Filter() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.91908 9.90054C8.16279 10.2532 8.08994 9.95934 8.08994 16.9891C8.08994 17.6182 8.80765 17.9782 9.31349 17.6019C11.475 15.9722 11.9069 15.8311 11.9069 15.0688C11.9069 9.94611 11.8472 10.2342 12.0777 9.90054L15.5878 5.12274H4.40906L7.91908 9.90054Z"
        fill="#E2374B"
      />
      <path
        d="M16.9137 2.63448C16.7882 2.39425 16.5423 2.2449 16.2715 2.2449H3.72531C3.1405 2.2449 2.7964 2.9046 3.13187 3.38393C3.13462 3.38854 3.09379 3.33265 3.74137 4.21397H16.2554C16.8072 3.46291 17.1761 3.1384 16.9137 2.63448Z"
        fill="#E2374B"
      />
    </svg>
  )
}

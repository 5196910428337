import React from "react"

export default function Upload() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_319_1541)">
        <path
          d="M29.2188 23.125V27.1875C29.2188 28.3077 28.3077 29.2188 27.1875 29.2188H2.8125C1.69227 29.2188 0.78125 28.3077 0.78125 27.1875V23.125C0.78125 22.5644 1.23523 22.1094 1.79688 22.1094C2.35852 22.1094 2.8125 22.5644 2.8125 23.125V27.1875H27.1875V23.125C27.1875 22.5644 27.6415 22.1094 28.2031 22.1094C28.7648 22.1094 29.2188 22.5644 29.2188 23.125Z"
          fill="black"
        />
        <path
          d="M7.17259 9.62423C6.77548 9.22712 6.77548 8.58524 7.17259 8.18813L14.2809 1.07977C14.6781 0.681649 15.327 0.686727 15.7191 1.07977L22.8274 8.18813C23.2245 8.58524 23.2245 9.22712 22.8274 9.62423C22.6294 9.82227 22.3694 9.9218 22.1094 9.9218C21.8494 9.9218 21.5894 9.82227 21.3913 9.62423L16.0156 4.24852V22.1093C16.0156 22.6699 15.5617 23.1249 15 23.1249C14.4384 23.1249 13.9844 22.6699 13.9844 22.1093V4.24852L8.60868 9.62423C8.21157 10.0213 7.5697 10.0213 7.17259 9.62423Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_319_1541">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

import { colors } from ".";

export const transactionStyle = {
  mainBox: { display: "flex", gap: "30px", mb: "30px" },
  textSize: {
    fontSize: "38px",
    lineHeight: "46px",
    width: "85%",
    wordBreak: "break-all",
    height: "40px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  paper: {
    boxShadow: "-5px 10px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "10px",
    border: "1px solid #DFDFDF",
  },
  indexBox: {
    borderBottom: 1,
    borderColor: colors.grey,
    display: "inline-block",
    position: "relative",
    zIndex: 1,
  },
  servicefeeBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mt: "10px",
  },
};

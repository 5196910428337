import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material"
import { Box } from "@mui/system"
import { useContext, useState } from "react"
import Excel from "../../components/Public-icons/excel"
import Filter from "../../components/Public-icons/filter"
import { TransactionContext } from "../../context/TransactionProvider"
import { colors } from "../../util"
import { constantStyle } from "../../util/constant"
import { transactionStyle } from "../../util/transactionStyle"
import { withdrawalrequestcolumns } from "./Data"
import "./style.css"

export default function WithdrawalRequest() {

  const { withdrawalRequest, exportFile } = useContext(TransactionContext)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  // export button function to export the data to excel file 
  const handleExport = () => {
    const dataToExport = withdrawalRequest.map((request, index) => ({
      srNo: index + 1,
      AccountHolder: request.accountHolder,
      Amount: "₹ " + `${request.amount}`,
      ifsc: request.ifscCode,
      accountNumber: request.accountNumber,
      BranchName: request.branchName,
      BankName: request.bankName,
      Date: new Date(
        request.createdAt.seconds * 1000 + request.createdAt.nanoseconds / 1000000
      ).toLocaleDateString(),
    }));
    exportFile(dataToExport, 'Withdrawal Request', 'withdrawalrequest.xlsx')

  };


  return (
    <Box>
      <Box className="rightbtn">
        <Button
          className="filterbtn"
          sx={constantStyle.filterBtn}
        >
          <Filter />
        </Button>
        <Button variant="contained" onClick={handleExport} sx={constantStyle.letterSpacing}>
          <Excel /> Export
        </Button>
      </Box>
      <Paper
        sx={transactionStyle.paper}
      >
        <TableContainer sx={{ overflowY: "auto" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {withdrawalrequestcolumns.map((column, index) => (
                  <TableCell
                    sx={{
                      textTransform: "uppercase",
                      color: colors.tabHeadTitle,
                      fontWeight: 700,
                      p: 1.3,
                    }}
                    key={index}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {withdrawalRequest.length > 0 ? (
              <TableBody>
                {withdrawalRequest.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, dataIndex) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={dataIndex}>
                      {withdrawalrequestcolumns.map((column, index) => {
                        const value = row[column.id];
                        const formattedValue = typeof value === "object"
                          ? new Date(value.seconds * 1000).toLocaleString("en-US", { year: "numeric", month: "short", day: "numeric" })
                          : value;

                        if (column.id === "id") {
                          return (
                            <TableCell key={dataIndex} align={column.align}>
                              {dataIndex + 1}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={`${dataIndex}-${index}`} align={column.align}>
                              {column.format && typeof value === "number" ? column.format(value) : formattedValue}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                <TableCell>No found data</TableCell>
              </TableBody>
            )}

          </Table>
        </TableContainer>
      </Paper>
      <TablePagination
        rowsPerPageOptions={[]}
        component="article"
        count={withdrawalRequest.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          native: false,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
}
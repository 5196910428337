import { Box } from "@mui/material"
import React from "react"
import Drawerleft from "../Drawerleft"
import "./style.css"

export default function Header() {
  return (
    <div className="headermain">
      <Box sx={{ flexGrow: 1 }}>
        <Drawerleft />
      </Box>
    </div>
  )
}

import React, { Fragment, useContext, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import * as clipboard from "clipboard-polyfill";
import Copy from "../../components/Public-icons/copy";
import { AlertMessageContext } from "../../context/AlertMessageProvider";
import { TransactionContext } from "../../context/TransactionProvider";
import { colors } from "../../util";
import { constantStyle } from "../../util/constant";

export default function ServiceFeemodal({ open, actions, data }) {
  const [toolopen, setToolopen] = useState(false);
  const [postlopen, setPostlopen] = useState(false);
  const { setShow, setMessage } = useContext(AlertMessageContext);
  const { formatDateTime } = useContext(TransactionContext);

  //post postId copy to clipboard  and setPostlopen is to show postId post link
  const handlePostidCopy = async (postId) => {
    setPostlopen(true);
    try {
      await clipboard.writeText(postId);
      setTimeout(() => {
        setPostlopen(false);
      }, 500);
    } catch (err) {
      setShow(true);
      setMessage({ text: "Failed to ID copy ", type: "error" });
    }
  };

  //post link copy to clipboard  and settoolopen is to show copy post link
  const handleTooltipOpen = async (link) => {
    setToolopen(true);
    try {
      await clipboard.writeText(link);
      setTimeout(() => {
        setToolopen(false);
      }, 500);
    } catch (err) {
      setShow(true);
      setMessage({ text: "Failed to link copy ", type: "error" });
    }
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        maxWidth="md"
        sx={{ width: "43%", margin: "auto" }}
        className="detailsmodal-main"
      >
        <DialogTitle sx={constantStyle.dialogTitle} variant="h3">
          Post Details
          <DialogActions onClick={actions} sx={constantStyle.dialogAction}>
            <CloseIcon sx={constantStyle.closeIcon} />
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={constantStyle.dialogContent}>
          <Box className="d-flex boxinfo" sx={constantStyle.dialogMainBox}>
            <Box sx={constantStyle.dialogInnerBox}>
              <Typography variant="h6" className="gap5">
                Creator Name
              </Typography>
              <Typography variant="body2">{data?.creatorName}</Typography>
            </Box>
            <Box>
              <Typography variant="h6" className="gap5">
                Collaborator Name
              </Typography>
              <Typography variant="body2">{data?.collaboratorName}</Typography>
            </Box>
          </Box>

          <Box className="d-flex boxinfo">
            <Box sx={{ width: "237px", mr: "40px" }}>
              <Typography variant="h6" className="gap5">
                Post ID
              </Typography>
              <Typography
                variant="body2"
                sx={{ wordBreak: "break-all" }}
                className="postidright"
              >
                {data?.postId}
                <Tooltip
                  placement="top"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  open={postlopen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Copied"
                >
                  <Button
                    onClick={() => handlePostidCopy(data?.postId)}
                    sx={{
                      p: 0,
                      marginLeft: "5px",
                      background: "none",
                      minWidth: "initial",
                      "&:hover": {
                        background: "none",
                      },
                    }}
                  >
                    <Copy />
                  </Button>
                </Tooltip>
              </Typography>
            </Box>
            <Box sx={{ width: "340px", pr: "0!important", mr: "20px" }}>
              <Typography variant="h6" className="gap5">
                Transaction ID
              </Typography>
              <Typography variant="body2">{data?.transactionId}</Typography>
            </Box>
            <Box>
              <Typography variant="h6" className="gap5">
                Contract ID
              </Typography>
              <Typography variant="body2">{data?.id}</Typography>
            </Box>
          </Box>

          <Box className="d-flex boxinfo">
            <Box sx={constantStyle.dialogInnerBox}>
              <Typography variant="h6" className="gap5">
                Type
              </Typography>
              <Typography variant="body2">{data?.type}</Typography>
            </Box>
            <Box>
              <Typography variant="h6" className="gap5">
                Date & Time
              </Typography>
              <Typography variant="body2">
                {formatDateTime(data?.createdAt.toDate())}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ mt: "15px" }}>
            <Typography variant="h6" className="gap5">
              Post Link
            </Typography>

            <Typography variant="body2" className="d-flex align-items-center">
              {data?.postLink}
              <Tooltip
                placement="top"
                PopperProps={{
                  disablePortal: true,
                }}
                open={toolopen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copied"
              >
                <Button
                  onClick={() => handleTooltipOpen(data?.postLink)}
                  sx={{
                    p: 0,
                    marginLeft: "5px",
                    background: "none",
                    minWidth: "initial",
                    "&:hover": {
                      background: "none",
                    },
                  }}
                >
                  <Copy />
                </Button>
              </Tooltip>
            </Typography>
          </Box>

          <Box sx={{ mt: "15px" }}>
            <Typography variant="h6" className="gap5">
              Description
            </Typography>
            <Typography variant="body2">{data?.description}</Typography>
          </Box>

          <Box sx={{ mt: "15px" }}>
            <Typography variant="h6" className="gap5">
              By Genre
            </Typography>
            <Box sx={{ display: "flex" }}>
              {data?.genre?.map((item, index) => (
                <Typography variant="body2" key={index}>
                  {item.label + (index !== data?.genre.length - 1 ? ", " : "")}
                </Typography>
              ))}
            </Box>
          </Box>

          <Box className="d-flex boxinfo">
            <Box sx={{ width: "240px", mr: "40px" }}>
              <Typography variant="h6" className="gap5">
                By Gender
              </Typography>
              <Typography variant="body2">{data?.gender}</Typography>
            </Box>
            <Box sx={{ width: "340px", pr: "0 !important", mr: "40px" }}>
              <Typography variant="h6" className="gap5">
                Platform
              </Typography>
              <Typography variant="body2">{data?.platform}</Typography>
            </Box>
            <Box sx={{ mr: "40px" }}>
              <Typography variant="h6" className="gap5">
                Amount
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: colors.primaryColor, fontWeight: "700" }}
              >
                {data?.amount === "Barter" ? "Barter" : "₹ " + data?.amount}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" className="gap5">
                Service Fee
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: colors.primaryColor, fontWeight: "700" }}
              >
                {data?.receiverAmount === "Barter"
                  ? "Barter"
                  : "₹ " + (data?.amount - data?.receiverAmount).toFixed(2)}
              </Typography>
            </Box>
          </Box>

          <Box className="boxinfo">
            <Box>
              <Typography variant="h6" className="gap5">
                Requirements
              </Typography>

              <Typography variant="body2">
                {data?.follower?.map((item, index) => (
                  <Typography variant="body2" key={index}>
                    {item?.range +
                      (index !== data?.follower.length - 1 ? ", " : "")}
                  </Typography>
                ))}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

import React from "react";

export const CustomerSupportIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_29_156)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4171 17.2643C11.4171 16.6077 10.8847 16.0753 10.228 16.0753H8.77199C8.45663 16.0753 8.15419 16.2006 7.9312 16.4236C7.70821 16.6465 7.58293 16.949 7.58293 17.2643C7.58293 17.921 8.1153 18.4534 8.77199 18.4534H10.228C10.8847 18.4534 11.4171 17.921 11.4171 17.2643ZM2.49972 14.1983C2.69833 14.2134 2.93862 14.2222 3.17998 14.208C3.30856 14.8601 3.628 15.4649 4.10482 15.9418C4.73783 16.5747 5.59635 16.9304 6.49154 16.9304H6.94524C6.92524 17.0398 6.91496 17.1516 6.91496 17.2643C6.91496 17.3784 6.92524 17.49 6.94495 17.5983H6.49154C5.41919 17.5983 4.39074 17.1723 3.63249 16.4141C3.02757 15.8091 2.6341 15.0323 2.49972 14.1983ZM2.15995 13.4875C1.63259 13.3986 1.14186 13.1479 0.758775 12.7648C0.272939 12.2789 0 11.62 0 10.9329V9.35415C0 8.66707 0.272939 8.00811 0.758775 7.52228C1.24461 7.03644 1.90356 6.76347 2.59064 6.76347H2.76309C3.03874 3.28424 5.94967 0.54657 9.5 0.54657C13.0503 0.54657 15.9613 3.28424 16.2369 6.76347H16.4094C17.0964 6.76347 17.7554 7.03644 18.2412 7.52228C18.7271 8.00811 19 8.66707 19 9.35415V10.9329C19 11.62 18.7271 12.2789 18.2412 12.7648C17.7554 13.2506 17.0964 13.5236 16.4094 13.5236H15.6449C15.3278 13.5236 15.0708 13.2665 15.0708 12.9495V7.30485C15.0708 4.22819 12.5767 1.73407 9.5 1.73407C6.42334 1.73407 3.92921 4.22819 3.92921 7.30485V12.9495C3.92921 13.1733 3.80108 13.3673 3.61412 13.462C3.09949 13.6405 2.30138 13.5114 2.15995 13.4875Z"
          fill="#"
        />
      </g>
      <defs>
        <clipPath id="clip0_29_156">
          <rect width="19" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import { Visibility, VisibilityOff } from "@mui/icons-material"
import {
  Button,
  CircularProgress,
  InputAdornment,
  InputLabel,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import IconButton from "@mui/material/IconButton"
import { signInWithEmailAndPassword } from "firebase/auth"
import React, { useContext, useRef, useState } from "react"
import { AlertMessageContext } from "../../context/AlertMessageProvider"
import { auth } from "../../firebase"
import { colors } from "../../util"
import "./style.css"

export default function Login() {
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  })
  const [loader, setLoader] = useState()
  const { setShow, setMessage } = useContext(AlertMessageContext)
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const emailInputref = useRef()
  const passwordInputRef = useRef()

  const onClickSignIn = () => {
    setLoader(true)
    let email = emailInputref.current.value
    let password = passwordInputRef.current.value
    signInWithEmailAndPassword(auth, email, password)
      .catch((err) => {
        setShow(true)
        setMessage({ text: "Invalid Email / Password ", type: "error" })
      })
      .finally(() => {
        setLoader(false)
      })
  }

  return (
    <div className="login-main d-flex justify-content-center align-items-center">
      <div className="login-left">
        <img src="images/login-screen-left.svg" alt="login-screen-left" />
      </div>
      <Paper className="loginbox">
        <img src="images/Logo.svg" alt="Logo" />
        <Typography style={{ marginTop: 50 }} variant="h1" align="center">
          Welcome!
        </Typography>
        <Typography align="center" variant="subtitle2">
          Please enter your login details below.
        </Typography>

        <form>
          <div className="form-group">
            <InputLabel>Email ID</InputLabel>
            <TextField
              inputRef={emailInputref}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="email"
              type="text"
              placeholder="Enter your Email ID"
              className="form-control"
            />
          </div>

          <div className="form-group">
            <InputLabel>Password</InputLabel>
            <TextField
              inputRef={passwordInputRef}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              placeholder="Enter your Password"
              className="form-control"
              onChange={handleChange("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? (
                        <VisibilityOff style={{ color: colors.primaryColor }} />
                      ) : (
                        <Visibility style={{ color: colors.primaryColor }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Link
            style={{ float: "right", marginBottom: 30 }}
            href="/forgot-password"
          >
            forgot password?
          </Link>
          <Button
            type="submit"
            disabled={loader}
            style={{ letterSpacing: "0.3em" }}
            fullWidth
            variant="contained"
            onClick={onClickSignIn}
          >
            {loader ? (
              <CircularProgress
                size={28}
                style={{ color: colors.primaryColor }}
              ></CircularProgress>
            ) : (
              "Login"
            )}
          </Button>
        </form>
      </Paper>
      <div className="login-right">
        <img src="images/login-screen-right.svg" alt="login-screen-right" />
      </div>
    </div>
  )
}

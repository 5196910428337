import React from "react"

export const TransactionsIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_29_143)">
        <path
          d="M15.4375 10.2793C15.1301 10.2793 14.8809 10.5285 14.8809 10.8359V13.9902H13.2109C12.9035 13.9902 12.6543 14.2395 12.6543 14.5469C12.6543 14.8543 12.9035 15.1035 13.2109 15.1035H15.4375C15.7449 15.1035 15.9941 14.8543 15.9941 14.5469V10.8359C15.9941 10.5285 15.7449 10.2793 15.4375 10.2793Z"
          fill="#"
        />
        <path
          d="M3.5625 8.7207C3.86991 8.7207 4.11914 8.47148 4.11914 8.16406V5.00977H5.78906C6.09648 5.00977 6.3457 4.76054 6.3457 4.45312C6.3457 4.14571 6.09648 3.89648 5.78906 3.89648H3.5625C3.25509 3.89648 3.00586 4.14571 3.00586 4.45312V8.16406C3.00586 8.47148 3.25509 8.7207 3.5625 8.7207Z"
          fill="#"
        />
        <path
          d="M10.9473 13.4336H9.0918C8.47693 13.4336 7.97852 13.932 7.97852 14.5469C7.97852 15.1617 8.47693 15.6602 9.0918 15.6602H10.9473C11.2547 15.6602 11.5039 15.4109 11.5039 15.1035V13.9902C11.5039 13.6828 11.2547 13.4336 10.9473 13.4336Z"
          fill="#"
        />
        <path
          d="M6.86523 14.5469C6.86523 13.3191 7.86407 12.3203 9.0918 12.3203H10.7617V11.7637C10.7617 10.8429 10.0126 10.0938 9.0918 10.0938H1.66992C0.749127 10.0938 0 10.8429 0 11.7637V17.3301C0 18.2509 0.749127 19 1.66992 19H9.0918C10.0126 19 10.7617 18.2509 10.7617 17.3301V16.7734H9.0918C7.86407 16.7734 6.86523 15.7746 6.86523 14.5469Z"
          fill="#"
        />
        <path
          d="M18.4434 3.33984H16.5879C15.973 3.33984 15.4746 3.83826 15.4746 4.45312C15.4746 5.06799 15.973 5.56641 16.5879 5.56641H18.4434C18.7508 5.56641 19 5.31718 19 5.00977V3.89648C19 3.58907 18.7508 3.33984 18.4434 3.33984Z"
          fill="#"
        />
        <path
          d="M14.3613 4.45312C14.3613 3.2254 15.3602 2.22656 16.5879 2.22656H18.2578V1.66992C18.2578 0.749127 17.5087 0 16.5879 0H9.16602C8.24522 0 7.49609 0.749127 7.49609 1.66992V7.23633C7.49609 8.15712 8.24522 8.90625 9.16602 8.90625H16.5879C17.5087 8.90625 18.2578 8.15712 18.2578 7.23633V6.67969H16.5879C15.3602 6.67969 14.3613 5.68085 14.3613 4.45312Z"
          fill="#"
        />
      </g>
      <defs>
        <clipPath id="clip0_29_143">
          <rect width="19" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

import React from "react"

export default function Addplus() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_319_1098)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.51627 0.708099C4.21468 0.708099 0.706793 4.21046 0.706787 8.51204C0.706777 12.8136 4.21467 16.3201 8.51627 16.3201C12.8179 16.3201 16.3188 12.8136 16.3188 8.51204C16.3188 4.21046 12.8179 0.708099 8.51627 0.708099ZM8.50518 4.95509C8.60022 4.95397 8.69451 4.97195 8.78247 5.00798C8.87042 5.044 8.95024 5.09733 9.01719 5.16479C9.08414 5.23225 9.13685 5.31248 9.1722 5.40071C9.20754 5.48894 9.2248 5.58337 9.22294 5.6784V7.80397H11.3485C11.5369 7.80397 11.7175 7.87879 11.8507 8.01197C11.9838 8.14515 12.0587 8.32577 12.0587 8.51411C12.0587 8.70246 11.9838 8.88308 11.8507 9.01626C11.7175 9.14944 11.5369 9.22426 11.3485 9.22426H9.22294V11.3498C9.22294 11.5382 9.14813 11.7188 9.01495 11.852C8.88177 11.9852 8.70114 12.06 8.5128 12.06C8.32446 12.06 8.14383 11.9852 8.01066 11.852C7.87748 11.7188 7.80266 11.5382 7.80266 11.3498V9.22426H5.67709C5.48879 9.23392 5.30435 9.16841 5.16435 9.04213C5.02434 8.91584 4.94022 8.73912 4.93048 8.55082C4.92074 8.36252 4.98619 8.17807 5.11243 8.03802C5.23866 7.89796 5.41535 7.81378 5.60365 7.80397C5.62811 7.80271 5.65262 7.80271 5.67709 7.80397H7.80266V5.6784C7.80083 5.58466 7.81761 5.49148 7.85201 5.40426C7.88641 5.31704 7.93776 5.2375 8.00308 5.17024C8.06841 5.10298 8.14641 5.04934 8.2326 5.01241C8.31878 4.97548 8.41143 4.956 8.50518 4.95509Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_319_1098">
          <rect width="17" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { brandStyle } from "../../util/brandStyle";

export default function PostsDetails() {
  const navigator = useNavigate();

  const { state } = useLocation();
  const data = state.postDetails;

  return (
    <Box className="brandpost-details cards-page">
      <Button
        style={brandStyle.backButton}
        variant="contained"
        onClick={() => navigator("/posts")}
      >
        <ArrowBack style={brandStyle.arrowBack} /> Back
      </Button>

      <Box className="brandposting-crds">
        <Card className="postcards">
          <Box className="postdetails">
            <Box sx={brandStyle.cardDetailsMainBox}>
              <Box sx={brandStyle.Boxsiz}>
                <CardContent>
                  <Typography variant="body1">Budget</Typography>
                  <Typography variant="subtitle2">{data.budget}</Typography>
                </CardContent>
              </Box>

              <Box sx={brandStyle.Boxsiz}>
                {data.type === "Create with Creators" && (
                  <CardContent>
                    <Typography variant="body1">Connect On</Typography>
                    <Box sx={brandStyle.categoryBox}>
                      <Typography variant="subtitle2">
                        {data.platform}
                      </Typography>
                    </Box>
                  </CardContent>
                )}

                {data.type === "Create with Creators" && (
                  <CardContent>
                    <Typography variant="body1">Topic</Typography>
                    <Box sx={brandStyle.categoryBox}>
                      <Typography variant="subtitle2">
                        {data.topic}
                      </Typography>
                    </Box>
                  </CardContent>
                )}

                {(data.type === "Give Shoutout" ||
                  data.type === "Shoutout for Shoutout") && (
                  <CardContent>
                    <Typography variant="body1">Completion Time</Typography>
                    <Box sx={brandStyle.categoryBox}>
                      <Typography variant="subtitle2">
                        {data.completion_time}
                      </Typography>
                    </Box>
                  </CardContent>
                )}
              </Box>

              <Box
                sx={brandStyle.Boxsiz}
                style={{ borderRight: "none", padding: 0 }}
              >
                {data.type === "Give Shoutout" ||
                  (data.type === "Shoutout for Shoutout" && (
                    <CardContent>
                      <Typography variant="body1">Post Link</Typography>
                      <Typography variant="subtitle2">
                        {data.postLink}
                      </Typography>
                    </CardContent>
                  ))}

                <CardContent>
                  <Typography variant="body1">Type</Typography>
                  <Typography variant="subtitle2">{data.type}</Typography>
                </CardContent>
              </Box>

              <CardContent sx={{ pt: "10px!important" }}>
                <Typography variant="body1">Description</Typography>
                <Typography variant="subtitle2">{data.description}</Typography>
              </CardContent>
            </Box>
            {data.image && (
              <CardMedia component="img" image={data.image} alt="Brand Logo" />
            )}
          </Box>
        </Card>
      </Box>
    </Box>
  );
}

import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where
} from "firebase/firestore";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable
} from "firebase/storage";
import _ from "lodash";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { fireStore, storage } from "../firebase";
import { AlertMessageContext } from "./AlertMessageProvider";
export const BrandPostingContext = createContext();

const BrandPostProvider = ({ children }) => {
  const [brandData, setBrandData] = useState([])
  const [brandDraftData, setBrandDraftData] = useState([])
  const { setShow, setMessage } = useContext(AlertMessageContext)
  const [collabUsers, setCollabUsers] = useState([])
  const [originalBrandData, setOriginalBrandData] = useState([])
  const [originalDraftBrandData, setOriginalDraftBrandData] = useState([])
  const [tabValue, setTabValue] = useState(0)
  const [searchText, setSearchText] = useState("")

  // retrive publish brandposting data  from firestore
  const getBrandData = useCallback(async () => {
    try {
      const localRef = query(collection(fireStore, "BRAND_POSTING"), where("publish", "==", true));
      const response = await getDocs(localRef);
      const brandPostData = response?.docs?.map((doc) => ({ ...doc.data(), id: doc.id })) ?? [];
      const filterData = _.orderBy(brandPostData, "createdAt", "desc");
      setBrandData(filterData);
      setOriginalBrandData(filterData);
    } catch (err) {
      setShow(true);
      setMessage({ text: "No data found", type: "error" });
    }
  }, [setShow, setMessage])

  // retrive draft brandposting data from firestore
  const getSaveDraftData = useCallback(async () => {
    try {
      const localRef = query(collection(fireStore, "BRAND_POSTING"), where("publish", "==", false));
      const response = await getDocs(localRef);
      const brandPostData = response?.docs?.map((doc) => ({ ...doc.data(), id: doc.id })) ?? [];
      const filterData = _.orderBy(brandPostData, "createdAt", "desc");
      setBrandDraftData(filterData);
      setOriginalDraftBrandData(filterData);
    } catch (err) {
      setShow(true);
      setMessage({ text: "No data found", type: "error" });
    }
  }, [setShow, setMessage]);

  //update brandposting data in firestore & updateBrand, path, id from create brandposting modal file
  const updateBrandData = async (updateBrand, path, id) => {
    try {
      const docRef = doc(fireStore, "BRAND_POSTING", id);
      await updateDoc(docRef, updateBrand);
      if (path) {
        const storageRef = ref(storage, `BrandPost/${id}`);
        const uploadImage = uploadBytesResumable(storageRef, path);
        const snapshot = await uploadImage;
        const downloadURL = await getDownloadURL(snapshot.ref);
        await updateDoc(docRef, { brandLogo: downloadURL });
      }
      await getBrandData();
      await getSaveDraftData();
    } catch (error) {
      setShow(true);
      setMessage({ text: "Data Not Update ", type: "error" });
    } finally {
      return;
    }
  };

  //delete brand data from firebase & deleteDocument from 
  const deleteBrandData = async (deleteDocument) => {
    new Promise((resolve, reject) => {
      const docRef = doc(fireStore, "BRAND_POSTING", deleteDocument.id);
      deleteDoc(docRef)
        .then(() => {
          if (deleteDocument.publish === true) {
            getBrandData();
            resolve();
          } else {
            getSaveDraftData();
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  //add brandposting data to firestore get data & path from create brandposting modal file
  const addBrandData = async (data, path) => {
    try {
      const newBrandPostRef = doc(collection(fireStore, "BRAND_POSTING"))
      await setDoc(newBrandPostRef, data)

      const storageRef = ref(storage, `BrandPost/${newBrandPostRef.id}`)
      await uploadBytes(storageRef, path)
      const uploadImage = uploadBytesResumable(storageRef, path)
      const snapshot = await uploadImage
      const downloadURL = await getDownloadURL(snapshot.ref)

      const documentRef = doc(fireStore, "BRAND_POSTING", newBrandPostRef.id)
      await updateDoc(documentRef, { brandLogo: downloadURL })

      getBrandData()
      getSaveDraftData()
    } catch (error) {
      setShow(true)
      setMessage({ text: error.message || "An error occurred", type: "error" })
    }
  }

  // Combined API (POST_COLLABORATION & USERS) for brandposting detail
  const getCollabUsers = useCallback(async (data) => {

    try {
      const localRef = doc(fireStore, "POST_COLLABORATION", data?.id);
      const localCollabRef = collection(localRef, "COLLABORATED_USERS");
      const localUsersRef = collection(fireStore, "USERS");

      const [collabUsersResponse, usersResponse] = await Promise.all([
        getDocs(localCollabRef),
        getDocs(localUsersRef)
      ]);

      const collabUsersData = collabUsersResponse?.docs?.map((doc) => ({ ...doc.data(), id: doc.id })) ?? [];
      const usersData = usersResponse?.docs?.map((doc) => ({ ...doc.data(), id: doc.id })) ?? [];

      const mergedData = collabUsersData.map((collabUser) => ({
        ...collabUser,
        gender: usersData.find((user) => user.id === collabUser.userId)?.gender,
      }));

      setCollabUsers(mergedData);

    } catch (err) {
      setShow(true);
      setMessage({ text: "No data found", type: "error" });
    }

  }, [setShow, setMessage]);

  // Filter data by searching 
  const filterData = (data, searchText) => {
    const lowercaseSearchText = searchText.toLowerCase();
    return data.filter((item) => {
      return (
        (item.brandName ?? '').toLowerCase().includes(lowercaseSearchText) ||
        (item.what_do_you_get ?? '').toLowerCase().includes(lowercaseSearchText) ||
        (item.requiredFollower?.text ?? '').toLowerCase().includes(lowercaseSearchText) ||
        (item.platform?.some((platform) => platform.text.toLowerCase().includes(lowercaseSearchText)) ?? false) ||
        (item.brandCategory?.some((category) => category.text.toLowerCase().includes(lowercaseSearchText)) ?? false) ||
        (item.deliverables?.some((deliverable) => deliverable.text.toLowerCase().includes(lowercaseSearchText)) ?? false)
      );
    });
  };


  // Filter publish brandposting  data by search  
  const getFilterBrandData = useCallback(() => {
    if (searchText === "") {
      setBrandData(originalBrandData);
    } else {
      const filteredData = filterData(originalBrandData, searchText);
      setBrandData(filteredData);
    }
  }, [originalBrandData, searchText]);

  // Filter draft brandposting  data by search
  const getFilterDraftBrandData = useCallback(() => {
    if (searchText === "") {
      setBrandDraftData(originalDraftBrandData);
    } else {
      const filteredData = filterData(originalDraftBrandData, searchText);
      setBrandDraftData(filteredData);
    }
  }, [originalDraftBrandData, searchText]);

  useEffect(() => {
    if (tabValue === 0) {
      getFilterBrandData();
    } else {
      getFilterDraftBrandData();
    }
  }, [searchText, getFilterBrandData, getFilterDraftBrandData, tabValue]);


  useEffect(() => {
    getBrandData();
    getSaveDraftData();
  }, [getBrandData, getSaveDraftData]);

  return (
    <BrandPostingContext.Provider
      value={{
        getBrandData,
        brandData,
        addBrandData,
        brandDraftData,
        getSaveDraftData,
        updateBrandData,
        getCollabUsers,
        collabUsers,
        deleteBrandData,
        tabValue,
        setTabValue,
        searchText,
        setSearchText,
      }}
    >
      {children}
    </BrandPostingContext.Provider>
  );
};

export default BrandPostProvider;

import React, { Fragment, useContext, useState } from "react";
import { DisputesContext } from "../../context/DisputesProvider";
import {
  Avatar,
  Box,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { groomStyle } from "../../util/groomStyle";
import { columns } from "./disputesColumnData";
import { slice } from "lodash";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { colors } from "../../util";
import Dispute_Modal from "./disputes-modal";

export const Disputes = () => {
  const { disputeData } = useContext(DisputesContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleclose = () => {
    setOpen(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getRowCell = (column, value, data) => {
    switch (column.id) {
      case "personName":
        return (
          <TableCell key={column.id} align={column.align}>
            <CardHeader
              sx={{ padding: 0, marginRight: "0" }}
              title={value}
              avatar={
                <Avatar
                  sx={{
                    height: 17,
                    width: 17,
                    marginRight: "0",
                  }}
                  // alt={value.name}
                  // src={value.profilePhoto}
                />
              }
            />
          </TableCell>
        );
      case "title":
        return (
          <TableCell key={column.id} align={column.align}>
            <Tooltip title={value}>
              <span>{value}</span>
            </Tooltip>
          </TableCell>
        );
      case "icon":
        return (
          <TableCell key={column.id} align={column.align}>
            <RemoveRedEyeIcon
              sx={{ color: colors.primaryColor, cursor: "pointer" }}
              onClick={() => setOpen(data)}
            />
          </TableCell>
        );
      default:
        return (
          <TableCell key={column.id} align={column.align}>
            {column.format && typeof value === "number"
              ? column.format(value)
              : value}
          </TableCell>
        );
    }
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const rowsToShow = slice(disputeData, startIndex, endIndex);

  return (
    <Box>
      {disputeData.length === 0 ? (
        <Typography variant="h5" color={{ color: colors.black }}>No data found</Typography>
      ) : (
        <Paper sx={groomStyle.paper}>
          <TableContainer sx={{ overflowY: "auto" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsToShow.map((disputeArray, index) => (
                  <Fragment key={index}>
                    {disputeArray.map((data) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={data.contractId}
                      >
                        {columns.map((column) => {
                          const value = data[column.id];
                          const cellData = getRowCell(column, value, data);
                          return cellData;
                        })}
                      </TableRow>
                    ))}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
      {disputeData.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="article"
          count={disputeData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            native: false,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <Dispute_Modal open={open} actions={handleclose} />
    </Box>
  );
};

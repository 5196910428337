import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where
} from "firebase/firestore";
import { orderBy } from "lodash";
import { createContext, useContext, useEffect, useState } from "react";
import { fireStore } from "../firebase";
import { AlertMessageContext } from "./AlertMessageProvider";
export const GroomContext = createContext();

const GroomContentProvider = ({ children }) => {
  const [groomData, setGroomData] = useState("");
  const { setShow, setMessage } = useContext(AlertMessageContext);

  const getGroomData = async () => {
    
    try {
      const groomDataRef = query(
        collection(fireStore, "POSTS"),
        where("type", "==", "Groom your Content")
      );

      const response = await getDocs(groomDataRef);
      let groomContentData = [];
      let userIds = [];

      response.forEach((doc) => {
        groomContentData.push({
          ...doc.data(),
          id: doc.id,
        });

        const userId = doc.data().userId;
        userIds.push(userId);
      });

      const userPromises = userIds.map((userId) => {
        const userRef = doc(collection(fireStore, "USERS"), userId);
        return getDoc(userRef);
      });

      const userResponses = await Promise.all(userPromises);

      const usersData = userResponses.map((userDoc) => ({
        id: userDoc.id,
        ...userDoc.data(),
      }));

      const combinedData = orderBy(
        groomContentData,
        ["createdAt"],
        ["desc"]
      ).map((groomData) => {
        const userData = usersData.find((user) => user.id === groomData.userId);
        const formattedDate = new Date(groomData.createdAt.toDate()).toLocaleString();
        return {
          ...groomData,
          personName: userData.displayName,
          phone: userData.phone,
          photo: userData.photo,
          createdAt: formattedDate,
        };
      });
      setGroomData(combinedData);
    } catch (error) {
      setShow(true);
      setMessage({ text: "No data found", type: "error" });
    }
  };
  useEffect(() => {
    getGroomData();
  }, []);


  return (
    <GroomContext.Provider
      value={{ groomData, getGroomData }}
    >
      {children}
    </GroomContext.Provider>
  );
};

export default GroomContentProvider;

import React from "react";
import { Route, Routes } from "react-router";
import BrandPostProvider from "../context/BrandPostProvider";
import GroomContentProvider from "../context/GroomContentProvider";
import { PostProvider } from "../context/PostProvider";
import { UserProvider } from "../context/UserProvider";
import BrandPosting from "../pages/brand-posting";
import Brandpostingdetails from "../pages/brand-postingDetails";
import CustomerSupport from "../pages/customer-support";
import Dashboard from "../pages/dashboard";
import GroomYourContent from "../pages/groom-your-content";
import Posts from "../pages/posts";
import PostsDetails from "../pages/posts-details";
import PushNotification from "../pages/push-notification";
import Transaction from "../pages/transaction";
import UserManagement from "../pages/user-management";

export default function Navigation() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="push-notification" element={<PushNotification />} />
      <Route path="transaction" element={<Transaction />}></Route>
      <Route path="customer-support" element={<CustomerSupport />} />
      <Route
        path="user-management"
        element={
          <UserProvider>
            <UserManagement />
          </UserProvider>
        }
      />
      <Route
        path="brand-posting"
        element={
          <BrandPostProvider>
            <BrandPosting />
          </BrandPostProvider>
        }
      />
      <Route
        path="brand-posting-details"
        element={
          <BrandPostProvider>
            <Brandpostingdetails />
          </BrandPostProvider>
        }
      />
      <Route
        path="groom-your-content"
        element={
          <GroomContentProvider>
            <GroomYourContent />
          </GroomContentProvider>
        }
      />
      <Route
        path="posts"
        element={
          <PostProvider>
            <Posts />
          </PostProvider>
        }
      />
      <Route
        path="posts-details/:id"
        element={
          <PostProvider>
            <PostsDetails />
          </PostProvider>
        }
      />
    </Routes>
  );
}

import React from "react"
import { Box } from "@mui/material"
import Navigation from "../../navigator"
import Header from "../Header"

export default function PageLayout({ children }) {
  return (
    <div className="screenbg">
      <Box>
        <Header />
        {children}
        <Navigation />
      </Box>
    </div>
  )
}

import CloseIcon from "@mui/icons-material/Close"
import {
  Box, DialogContent,
  IconButton,
  Typography
} from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import React, { Fragment } from "react"
import { constantStyle } from "../../util/constant"
import { FileCopy } from "@mui/icons-material"

export default function UserModal({ open, actions }) {
  const rowData = open

  return (
    <Fragment>
      <Dialog
        open={open}
        maxWidth="md"
        sx={{ width: "43%", margin: "auto" }}
        className="detailsmodal-main"
      >
        <DialogTitle
          sx={constantStyle.dialogTitle}
          variant="h3"
        >
          User's Details
          <DialogActions
            onClick={actions}
            sx={constantStyle.dialogAction}
          >
            <CloseIcon sx={constantStyle.closeIcon} />
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={constantStyle.dialogContent}>
          <Box className="d-flex boxinfo" sx={constantStyle.dialogMainBox}>
            <Box sx={constantStyle.dialogInnerBox}>
              <Typography variant="h6" className="gap5">
                Creator Name
              </Typography>
              <Typography variant="body2">{rowData.personName}</Typography>
            </Box>
            <Box sx={{ width: "80px", mr: "30px" }}>
              <Typography variant="h6" className="gap5">
                Phone No.
              </Typography>
              <Typography variant="body2">{rowData.phone}</Typography>
            </Box>
            <Box>
              <Typography variant="h6" className="gap5">
                Gender
              </Typography>
              <Typography variant="body2">{rowData.gender}</Typography>
            </Box>
          </Box>

          <Box className="d-flex boxinfo">
            {/* <Box sx={constantStyle.dialogInnerBox}>
              <Typography variant="h6" className="gap5">
                Duration
              </Typography>
              <Typography variant="body2">Upto 10s</Typography>
            </Box> */}
            {/* <Box sx={{ width: "80px", mr: "30px" }}>
              <Typography variant="h6" className="gap5">
                Amount
              </Typography>
              <Typography variant="body2">₹ 300</Typography>
            </Box> */}
            {/* <Box>
              <Typography variant="h6" className="gap5">
                Type of Professional
              </Typography>
              <Typography variant="body2">Voice Over</Typography>

            </Box> */}
          </Box>

          <Box className="d-flex boxinfo">
            <Box sx={constantStyle.dialogInnerBox}>
              <Typography variant="h6" className="gap5">
                Date
              </Typography>
              <Typography variant="body2">{}</Typography>
            </Box>
            <Box sx={{ width: "80px", mr: "30px" }}>
              <Typography variant="h6" className="gap5">
                Email Id
              </Typography>
              <Typography variant="body2">{rowData.email}</Typography>
            </Box>
          </Box>

          <Box sx={{ mt: "15px" }}>
            <Typography variant="h6" className="gap5">
              Creator Id
            </Typography>
            <Typography variant="body2">
            {rowData.customerId}
            <IconButton
                aria-label="copy"
                onClick={() => {
                  navigator.clipboard.writeText(rowData.customerId);
                }}
              >
                <FileCopy />
              </IconButton>
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}


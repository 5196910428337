export const columns = [
    { 
        id: "personName", 
        label: "Creator Name" 
    },
    { 
        id: "customerId",
        label: "Creator Id",
    },
    {
        id: "phone",
        label: "Phone no.",
        align: "left",
        minWidth: 100,
    },
    {
        id: "gender",
        label: "Gender",
        align: "left",
    },
    {
        id: "email",
        label: "Email Id",
        align: "left",
    },
    {
        id: "createdAt",
        label: "Date & Time",
        align: "left",
    },
]

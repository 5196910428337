import React from "react"

export default function GroomContent() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_313_549)">
        <path
          d="M13.2444 7.52221C13.5631 6.86203 14.1156 6.36569 14.8027 6.12612C15.0901 6.02562 15.3864 5.98707 15.6814 5.98569V2.06522C15.6814 0.926594 14.7667 0 13.6427 0H2.76949C1.64548 0 0.730774 0.926594 0.730774 2.06522V15.8333C0.730774 16.972 1.64548 17.8986 2.76949 17.8986H9.53056L9.45717 17.6824C9.16155 16.8088 9.21932 15.8753 9.61823 15.0499L13.2444 7.52221ZM3.44907 4.13043H11.6039C11.9797 4.13043 12.2835 4.43884 12.2835 4.81884C12.2835 5.19884 11.9797 5.50725 11.6039 5.50725H3.44907C3.07326 5.50725 2.76949 5.19884 2.76949 4.81884C2.76949 4.43884 3.07326 4.13043 3.44907 4.13043ZM3.44907 6.88406H10.9244C11.3002 6.88406 11.6039 7.19246 11.6039 7.57246C11.6039 7.95246 11.3002 8.26087 10.9244 8.26087H3.44907C3.07326 8.26087 2.76949 7.95246 2.76949 7.57246C2.76949 7.19246 3.07326 6.88406 3.44907 6.88406ZM8.20607 13.7681H3.44907C3.07326 13.7681 2.76949 13.4597 2.76949 13.0797C2.76949 12.6997 3.07326 12.3913 3.44907 12.3913H8.20607C8.58188 12.3913 8.88565 12.6997 8.88565 13.0797C8.88565 13.4597 8.58188 13.7681 8.20607 13.7681ZM3.44907 11.0145C3.07326 11.0145 2.76949 10.7061 2.76949 10.3261C2.76949 9.94609 3.07326 9.63768 3.44907 9.63768H9.56522C9.94102 9.63768 10.2448 9.94609 10.2448 10.3261C10.2448 10.7061 9.94102 11.0145 9.56522 11.0145H3.44907ZM18.1306 9.93714L14.503 17.4676C14.2631 17.9639 13.8479 18.3357 13.3335 18.5147L12.0484 18.9621C11.9757 18.9876 11.9002 19 11.8275 19C11.5455 19 11.2818 18.821 11.1853 18.5353L10.7429 17.2336C10.5656 16.7104 10.6002 16.15 10.8394 15.655L14.467 8.12594C14.6253 7.79551 14.9026 7.54768 15.2458 7.4279C15.5883 7.30812 15.9573 7.33014 16.2835 7.49261L17.5054 8.09634C17.8315 8.25743 18.0762 8.5383 18.1944 8.88594C18.3127 9.23359 18.2903 9.6067 18.1306 9.93714Z"
          fill="#"
        />
      </g>
      <defs>
        <clipPath id="clip0_313_549">
          <rect width="19" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

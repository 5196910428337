export const columns = [
  {
    id: "personName",
    label: "Dispute By",
  },
  {
    id: "title",
    label: "Title",
  },
  {
    id: "againstWhom",
    label: "Against Whom",
  },
  {
    id: "contractId",
    label: "Contract Id",
    align: "left",
    minWidth: 100,
  },
  {
    id: "amount",
    label: "Amount",
  },
  {
    id: "createdAt",
    label: "Date & Time",
    align: "left",
},
  {
    id: "icon",
    minWidth: 20,
    align: "center",
  },
];

export const requirementsData = [
    {
        key: 1,
        text: "1k-10k followers"
    },
    {
        key: 2,
        text: "10k-20k followers"
    },
    {
        key: 3,
        text: "20k-50k followers"
    },
    {
        key: 4,
        text: "50k plus followers"
    }

]
import { Alert, Snackbar } from "@mui/material"
import { React, useContext } from "react"
import { AlertMessageContext } from "../../context/AlertMessageProvider"
import "./style.css"

const AlertMessage = () => {
  const { show, message, handleClose } = useContext(AlertMessageContext)
  return (
    <div>
      <Snackbar
        className="snackbar"
        open={show}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={message.type ? message.type : "warning" }
          sx={{ backgroundColor: "#E2374B !important", color: "white" }}
        >
          {message.text}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default AlertMessage

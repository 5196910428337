import { colors } from "."
export const customerStyle = {
    createModal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "20px",
        borderBottom: "solid 1px #DFDFDF",
        mb: "20px",
    },
    modalActions: {
        p: 0,
        background: colors.primaryColor,
        borderRadius: "50%",
        width: 22,
        height: 22,
        justifyContent: "center",
        cursor: "pointer",
    },
    submitBtn: {
        letterSpacing: "0.3em",
        lineHeight: "17px",
        color: colors.white,
        p: "12px 20px",
        mt: "20px",
        height: '37px',
        width: "120px",
        fontSize: "14px",
    },
    tabPanel: {
        borderBottom: 1,
        borderColor: colors.grey,
        display: "inline-block",
        position: "relative",
        zIndex: 1,
    }

}